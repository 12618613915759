$color-vk-blue: #4d76a1;
/* Tooltip */
.social-login,
.social-info {
  .tooltip {
    margin-top: 10px;
    letter-spacing: 0.05em;
    font-size: 15px;
    text-align: $ldirection;
    position: relative;
    display: inline-block;
    width: 100%;
    .tooltiptext-over {
      visibility: hidden;
      width: 100%;
      background-color: $color-dark-gray;
      color: $color-white;
      text-align: justify;
      border-radius: 6px;
      padding: 10px;
      position: absolute;
      z-index: 99;
      left: 0%;
      opacity: 0;
      transition: opacity 0.3s;
      font-weight: normal;
      bottom: 100%;
      overflow-y: scroll;
      max-height: 150px;
      height: auto;
    }
    a {
      color: $color-white;
      text-decoration: underline;
    }
    .tooltiptext-over::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 10%;
      border-width: 5px;
      border-style: solid;
      border-color: $color-dark-gray transparent transparent transparent;
    }
    &:hover .tooltiptext-over {
      visibility: visible;
      opacity: 1;
    }
  }
  &__connect {
    display: flex;
    margin-bottom: 20px;
    @media #{$medium-up} {
      margin-bottom: 20px;
      flex-wrap: wrap;
    }
    > div {
      display: flex;
      @media #{$medium-up} {
        flex: 0 0 100%;
      }
      margin-bottom: 20px;
    }
    .social-info__vk {
      button,
      input[type='submit'] {
        height: 20px;
        line-height: 20px;
        padding: 0 30px;
      }
      button {
        margin-#{$ldirection}: 22px;
      }
      .vkontakte-login {
        background-color: $color-vk-blue;
        background-image: url(/media/images/social_login/vk_icon_21x21.png);
        background-repeat: no-repeat;
        background-position: 2px 0px;
        max-width: 95%;
        letter-spacing: 1.25px;
        font-size: 8px;
        @include swap_direction(padding, 0px 5px 0px 27px);
        margin-left: 18px;
        border-radius: 4px;
      }
    }
  }
  &__terms {
    display: none;
  }
  .disclaimer_tooltip {
    display: none;
  }
}

.fb-disclaimer-error {
  color: $color-red;
  padding-bottom: 10px;
}

.social-login {
  position: relative;
  label {
    text-transform: none;
    margin-top: 10px;
    text-align: $ldirection;
  }
  &__blocker {
    position: absolute;
    z-index: 10000;
    width: 100%;
    height: 100%;
    background-color: $color-white;
    opacity: 0.5;
    &-hidden {
      display: none;
    }
  }
}

.fb-overlay-container {
  width: 265px;
  height: 40px;
  position: relative;
  margin: 0 auto;
  .fb-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}

.fb-overlay-social-info {
  width: 164px;
  height: 22px;
  position: relative;
  margin-#{$ldirection}: 22px;
  .fb-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}
/* Checkout Signin Page */
#signin {
  .co_updates_enabled {
    .pg_wrapper.sign-in-page.checkout__content {
      @media #{$xlarger-only} {
        min-height: 100vh;
        height: auto;
      }
    }
  }
}

.device-pc {
  .pg_wrapper.account-page.sidebar-page.social-section {
    overflow: inherit;
  }
}

label.fb-disclaimer {
  letter-spacing: 0.05em;
  text-transform: lowercase;
  a {
    text-decoration: underline;
  }
}
/* Gnav Content */
#cboxLoadedContent {
  .social-login.gnav {
    .tooltip.disclaimer_tooltip {
      display: none;
    }
  }
}
/* Book appointment page styling */
.appt-book-section-content {
  .social-login {
    .tooltip.disclaimer_tooltip {
      display: none;
    }
  }
}
/* Account Signin Page */
#registration {
  .social-info {
    @media #{$medium-up} {
      margin-top: 100px;
    }
  }
}
/* Hide FB section in registration confirmation */
.registration-confirmation {
  .social-login.gnav {
    display: none;
  }
}
