#onetrust-pc-sdk {
  .ot-toggle {
    .checkbox {
      label {
        &:after {
          opacity: 1;
          transform: scale(1);
          border: none;
        }
      }
    }
  }
}

body {
  .embeddedServiceHelpButton {
    z-index: auto;
    .helpButton {
      #{$ldirection}: 0;
      bottom: 50px;
      width: 50%;
    }
  }
}

.spp-product {
  &__mini-bag-show {
    bottom: 117px;
  }
}

.pg_wrapper {
  &.checkout {
    @media #{$large-up} {
      margin-#{$ldirection}: auto;
      margin-#{$rdirection}: auto;
    }
  }
}

.page-utilities__account,
.utility-nav__account {
  @media #{$medium-up} {
    #{$ldirection}: -20px;
  }
  @media #{$xlarge-up} {
    #{$ldirection}: -10px;
  }
}

.utility-nav__cart {
  @media #{$medium-up} {
    #{$ldirection}: 30px;
  }
  @media #{$xlarge-up} {
    #{$ldirection}: 50px;
  }
}
