.enhanced_delivery_page {
  .page-wrapper {
    .pg_wrapper {
      .checkout_container {
        #content.checkout__content {
          #gift-options {
            background: $color-white;
            border-bottom: 1px solid $color-navy;
            border-top: 1px solid $color-navy;
            margin-bottom: 16px;
            max-width: 100%;
            padding: 13px 15px;
            width: 100%;
            .gift-options__title {
              color: $color-navy;
              font-family: $optimatext;
              font-size: 16px;
              font-weight: normal;
              letter-spacing: 0.25px;
              line-height: 1.25;
              margin-bottom: 0;
              text-transform: uppercase;
              .title-icon {
                background: none;
                float: $rdirection;
                height: 16px;
                line-height: 0.3;
                margin-top: 7px;
                width: 16px;
                &::before {
                  background: $color-navy;
                  content: '';
                  display: block;
                  height: 2px;
                  position: relative;
                  top: 15px;
                  transform: translate(-50%, -50%);
                  width: 16px;
                }
                &::after {
                  background: $color-navy;
                  color: $color-navy;
                  content: '';
                  display: block;
                  height: 16px;
                  position: relative;
                  top: 13px;
                  transform: translate(-50%, -50%);
                  width: 2px;
                }
              }
              &.open {
                .title-icon {
                  &::after {
                    visibility: hidden;
                  }
                }
              }
            }
            .gift_img_container {
              float: $ldirection;
              width: 100px;
              img {
                height: auto;
                margin: 0;
                max-width: 100%;
              }
              .gift_img {
                margin: 0;
                width: 80%;
              }
            }
            .gift-options-container {
              float: $rdirection;
              padding-#{$ldirection}: 24px;
              width: calc(100% - 100px);
              .form-item,
              .form-item-gift-message {
                .field {
                  border: 1px solid $color-navy;
                }
                label {
                  &::before {
                    color: $color-light-gray;
                    font-size: 12px;
                    letter-spacing: 1.5px;
                  }
                }
              }
              .form-item-gift-message {
                .field {
                  &.adaptive-label-mode ~ label::before {
                    transform: translateY(-22px) scale(0.8, 0.8);
                  }
                }
                label {
                  padding-top: 14px;
                }
              }
              .form-item {
                .field {
                  &.adaptive-label-mode ~ label::before {
                    transform: translateY(-27px) scale(0.8, 0.8);
                  }
                }
              }
            }
            .gift-options-radio-btn-container {
              margin-top: 14px;
              padding-#{$ldirection}: 24px;
              .gift-option-title {
                #{$rdirection}: 0;
                font-size: 14px;
                line-height: 1;
                letter-spacing: 0.25px;
                position: absolute;
                top: -30px;
                width: 100%;
              }
              .label-content {
                .gift-yes {
                  display: none;
                }
              }
              .is-gift-input {
                & ~ label {
                  display: block;
                  margin-top: 29px;
                  padding: 16px 56px;
                  &::before {
                    #{$ldirection}: 0;
                    border-radius: 20px;
                    background-color: $color-dark-grey;
                    content: '';
                    height: 16px;
                    position: absolute;
                    top: 16px;
                    width: 40px;
                  }
                  &::after {
                    #{$ldirection}: 0;
                    background: $color-white;
                    border: 2px solid $color-white;
                    border-radius: 50%;
                    box-shadow: 0 1px 2px 0 $color-dark-border-grey;
                    content: '';
                    height: 20px;
                    opacity: 1;
                    position: absolute;
                    top: 12px;
                    transform: scale(1);
                    transition: all 0.3s ease;
                    width: 20px;
                  }
                }
                &:checked {
                  & ~ label {
                    &::after {
                      #{$ldirection}: 16px;
                    }
                    &::before {
                      background-color: $color-medium-green;
                    }
                    .label-content {
                      margin-#{$ldirection}: 0;
                      .gift-yes {
                        display: block;
                      }
                      .gift-no {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
