@mixin co_updates_shared {
  .co_updates_enabled {
    .pg_wrapper {
      #top-viewcart-buttons,
      #checkout_shipping,
      #checkout_samples,
      #checkout_review,
      #review-panel,
      .checkout__content {
        .btn-viewcart-checkout,
        #add-samples,
        #continue-btn,
        .btn_continue_checkout,
        .samples-buttons.bottom {
          @media #{$medium-down} {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            box-shadow: 0px -3px 6px $color-light-gray;
            text-align: center;
            z-index: 999;
            margin-bottom: 0px;
          }
          a.btn,
          input.btn {
            @media #{$medium-down} {
              display: block;
              margin: 0;
              padding: 0.5em;
              height: 5em;
              width: 100%;
              background-color: $color-navy;
              border: $checkout-spacing--small solid $color-white;
              color: $color-white;
              font-weight: normal;
              line-height: 3em;
            }
          }
          .go-shopping.disabled {
            background-color: $color-light-gray;
            border: 1px solid;
          }
        }
      }
      #confirmation-page {
        header {
          text-align: center;
          margin-bottom: 15px;
        }
        @media #{$medium-down} {
          margin-#{$ldirection}: 0px;
          margin-#{$rdirection}: 0px;
        }
        #confirmation-number {
          margin: 0;
          padding: $checkout-spacing--large $checkout-spacing--extra-large;
          background-color: $color-gray-cs-side;
          text-align: center;
          a {
            text-decoration: none !important;
          }
          .confirmation-panel-confirmation-number {
            font-weight: bold;
            font-family: arial;
          }
        }
        #create_account_form_container {
          input {
            &.error[type='password'] {
              @media #{$medium-up} {
                border-color: red;
              }
              @media #{$medium-down} {
                border-color: red;
              }
            }
          }
        }
        #confirmation-email {
          @media #{$medium-down} {
            padding: $mpp-swatch-total $search-swatch-container;
          }
          @media #{$medium-up} {
            padding-top: 18px;
          }
        }
        #return-user {
          .email-address {
            label {
              margin-bottom: 0;
            }
          }
        }
        .confirmation-panel__actions {
          @media #{$medium-down} {
            @include swap_direction(padding, 0 24px);
          }
          .form-submit {
            @media #{$medium-down} {
              line-height: 18px;
              @include swap_direction(padding, 10px 0px 10px 0px);
            }
          }
        }
        .sub_form {
          @media #{$medium-down} {
            @include swap_direction(padding, 20px 24px 0px 24px);
          }
          border-top: 1px solid $color-light-gray;
          padding-top: 20px;
          .confirm-header,
          .shipping-header {
            font-size: 24px;
            font-family: $optimaregular;
            color: $color-navy;
            letter-spacing: 0;
            text-align: $ldirection;
          }
          .confirm-header {
            @media #{$medium-up} {
              margin-bottom: 20px;
            }
          }
          .shipping-header {
            @media #{$medium-down} {
              @include swap_direction(padding, 23px 0px 0px 0px);
              font-size: 18px;
            }
          }
          .error_messages {
            @media #{$medium-down} {
              padding: 0;
            }
          }
          .shipping-panel__title {
            @media #{$medium-up} {
              @include swap_direction(padding, 18px 0px 0px 0px);
              border-bottom: none;
              font-size: 24px;
              color: $color-navy;
            }
          }
          .privacy_policy_container {
            @include swap_direction(padding, 0px);
            label {
              text-transform: none;
              letter-spacing: 0.05em;
            }
          }
          .result-submit {
            @media #{$medium-down} {
              margin-top: 23px;
            }
          }
        }
        .opc__footer {
          @media #{$medium-down} {
            background-color: $color-white;
            margin-top: 0px;
          }
        }
        .pc_email_container {
          label,
          .email-confirm {
            margin-bottom: 3px;
            font-size: 14px;
            color: $color-navy;
            letter-spacing: 0em;
            text-transform: capitalize;
          }
          .email-confirm {
            display: inline-block;
            font-weight: bold;
          }
        }
        #print-order {
          .form-submit {
            @media #{$medium-up} {
              margin-bottom: 18px;
            }
          }
        }
        .order_confirm_thank_you_msg {
          padding: 0 24px;
        }
      }
      &.checkout {
        z-index: inherit;
      }
      #viewcart-panel,
      .review-panel,
      .payment-panel {
        .remove_link {
          display: block;
          width: 15px;
          height: 15px;
          background-image: url('/media/images/checkout/close_button.jpg');
          @media #{$medium-up} {
            top: 0px;
            width: 36px;
            height: 36px;
          }
          background-repeat: no-repeat;
          background-size: contain;
          text-indent: -9999px;
          top: 20px;
          right: 0;
          position: absolute;
        }
        .cart-item__total {
          top: inherit;
          @media #{$medium-up} {
            float: $rdirection;
          }
          @media #{$medium-down} {
            padding-top: 10px;
          }
        }
      }
      .cart-item {
        @media #{$medium-up} {
          border-bottom: none;
          padding-top: 0;
        }
        &__desc {
          width: 75%;
          @media #{$medium-up} {
            width: 70%;
          }
          padding-top: 0;
          float: $ldirection;
          clear: none;
          text-align: $ldirection;
          .product_subname,
          .product_name,
          .name,
          .sub-name {
            padding-#{$rdirection}: 36px;
            font-weight: normal;
            display: block;
          }
          .price {
            @media #{$medium-down} {
              font-size: 12px;
              letter-spacing: 0.05em;
              padding-top: 10px;
            }
            .discount-price {
              @media #{$medium-down} {
                display: block;
              }
            }
          }
        }
        &__price {
          font-weight: normal;
        }
        &__qty {
          text-align: $ldirection;
          padding-top: 20px;
          width: auto;
          top: 0;
        }
        &__thumb {
          @media #{$medium-up} {
            width: 30% !important;
          }
        }
        &__actual-price {
          color: $color-navy;
          opacity: 0.4;
        }
        &s {
          margin-bottom: 0px;
          border-bottom: 0px;
          @media #{$medium-up} {
            border-bottom: 1px solid $color-light-gray;
            margin-bottom: 20px;
          }
        }
      }
      .continue-shopping {
        @media #{$medium-up} {
          display: none;
        }
      }
      section.sign-in-panel {
        display: block;
        @media #{$medium-down} {
          border-top: none !important;
          margin-top: 0px !important;
          padding-top: 0px !important;
          .checkout-header {
            margin-top: 25px !important;
          }
        }
      }
      .payment-panel {
        .payment-type {
          display: block;
        }
      }
      #checkout_shipping {
        .gift-options {
          &__title {
            @extend %checkout-subtitle !optional;
            position: relative;
            margin-bottom: 0;
            cursor: pointer;
            @media #{$medium-up} {
              .title-icon {
                background-size: auto auto;
                background-position: 0 -3269px;
                width: 53px;
                height: 53px;
                position: absolute;
                top: -3px;
                #{$checkout-right}: -10px;
                @media #{$large-up} {
                  top: -12px;
                }
              }
              &.open {
                margin-bottom: $checkout-spacing--large;
                .title-icon {
                  background-size: auto auto;
                  background-position: 0 -2957px;
                  width: 53px;
                  height: 53px;
                }
              }
            }
          } // &__title

          &__content {
            margin-top: 0;
            padding: $checkout-spacing--large $checkout-spacing--extra-large;
            border-bottom: 1px solid #dddddd;
            border-top: 1px solid #dddddd;
            @media #{$large-up} {
              padding: $checkout-spacing--large $checkout-spacing--medium;
              border-bottom: $checkout-line-lighter-gray;
            }
            .gift-message__title {
              display: none;
            }
            p {
              margin-bottom: $checkout-spacing--medium;
              font-size: $checkout-font-size--base;
            }
            #gift-wrap-img {
              @media #{$medium-up} {
                margin-top: 5px;
                width: auto;
                margin-#{$ldirection}: 0px;
              }
            }
          }
        }
        .required-note {
          font-size: 15px;
          letter-spacing: 0.75px;
          @media #{$medium-down} {
            letter-spacing: 0.05em;
          }
        }
      }
    }
    &.has-mobile-checkout-nav,
    &.is-min-nav {
      .page-header {
        .mobile-checkout-back {
          @media #{$medium-up} {
            display: none !important;
          }
          .back-button__left-caret-icon {
            background-size: auto auto;
            background-position: 0 -8820px;
            width: 30px;
            height: 40px;
            position: absolute;
            top: 5px;
          }
        }
        .page-navigation {
          @media #{$medium-up} {
            display: none;
          }
          &__menu-toggle {
            display: none;
          }
        }
        .page-utilities {
          display: block;
          @media #{$medium-up} {
            display: none;
          }
          @media #{$medium-down} {
            display: none;
          }
          &__search-button {
            @media #{$medium-down} {
              display: none;
            }
          }
          &__account,
          &__cart,
          &__search {
            @media #{$medium-up} {
              display: block;
            }
          }
        }
        .page-branding {
          &__logo {
            @media #{$medium-down} {
              margin-#{$ldirection}: -87.98px;
            }
          }
        }
      }
      .page-footer {
        @media #{$medium-down} {
          display: none !important;
        }
      }
      .opc__footer {
        @media #{$medium-down} {
          display: block !important;
        }
        margin: 0;
        margin-top: $checkout-spacing--large;
        padding: $checkout-spacing--large 24px;
        padding-bottom: 8em;
        background-color: $color-lighter-gray;
        text-align: center;
        small {
          font-size: $checkout-font-size--base;
        }
      } // .opc__footer

      .opc__footer-info {
        p {
          margin: $checkout-spacing--medium 0;
          color: $color-navy;
          font-weight: bold;
        }
        a {
          border-bottom: 2px solid $color-navy;
        }
        body.active-panel-signin &,
        body.active-panel-registration & {
          display: none;
        }
      } // .opc__footer

      .opc__footer-copyright {
        @media #{$large-up} {
          display: none;
        }
      }
      .responsive-footer {
        @media #{$medium-up} {
          display: none !important;
        }
      }
      #address_form_container,
      #billing_address_form_container {
        .first_name_container {
          width: 100%;
          @media #{$medium-up} {
            width: 50%;
            float: $ldirection;
          }
        }
        .last_name_container {
          width: 100%;
          @media #{$medium-up} {
            float: $rdirection;
            width: 50%;
          }
        }
      }
      .progress_bar_lable {
        width: 90%;
        display: block;
        word-break: break-word;
      }
      &#samples,
      &#shipping,
      &#billing,
      &#review,
      &#payment,
      &#signin,
      &#confirm {
        @media #{$medium-down} {
          .site-banner-formatter {
            display: none;
          }
          .top-thin-border {
            border-top: 1px solid $color-black !important;
          }
          .bottom-thin-border {
            border-bottom: 1px solid $color-black !important;
          }
        }
      }
      &#signin,
      &#shipping,
      &#billing {
        @media #{$medium-down} {
          &.page-wrapper-checkout {
            padding-top: 0px;
          }
          .page-wrapper {
            padding-top: 52px !important;
          }
        }
      }
      &#shipping,
      &#payment {
        #viewcart-panel {
          .cart-item {
            &__desc {
              .product_subname,
              .product_name,
              .name,
              .sub-name {
                padding-#{$rdirection}: 36px;
                font-weight: normal;
                display: inline-block;
                width: 100%;
                font-size: 12px;
              }
              .name {
                color: $color-sky-blue-medium;
                text-transform: uppercase;
                font-weight: bold;
                a {
                  color: $color-sky-blue-medium;
                }
              }
            }
          }
        }
      }
    }
    /// Adaptive Placeholder Adjustments
    ///
    .adpl {
      input[type='email'],
      input[type='tel'],
      input[type='password'],
      input[type='text'] {
        @include adaptive-placeholder($checkout-adaptive-placeholder-parameters);
        border: $checkout-line--input;
        height: 4em;
        height: calc(4em - -2px);
        &:focus,
        &.active,
        &.js-label-mode {
          & + label:before {
            -webkit-transform: translateY(-1em - 0.8em) translateY(-1px * 0.5) scale(0.8, 0.8);
            transform: translateY(-1em - 0.8em) translateY(-1px * 0.5) scale(0.8, 0.8);
          }
        }
        &:disabled {
          color: #888;
          & + label:before {
            color: #888;
          }
        }
        @media #{$large-up} {
          height: 3.5em;
          height: calc(3.5em - -2px);
          border: $checkout-line--input-light-gray;
          &:focus,
          &.active,
          &.js-label-mode {
            & + label:before {
              -webkit-transform: translateY(-1em - 0.6em) translateY(-1px * 0.5) scale(0.8, 0.8);
              transform: translateY(-1em - 0.6em) translateY(-1px * 0.5) scale(0.8, 0.8);
            }
          }
        }
        & + label,
        .label-content {
          @media #{$large-up} {
            line-height: 4.4em;
          }
        }
      }
      select,
      select:required,
      .selectBox {
        border: $checkout-line--input;
        height: 4em;
        height: calc(4em - -2px);
        &:disabled {
          color: #888;
        }
        @media #{$large-up} {
          border: $checkout-line--input-light-gray;
          height: 3.5em;
          height: calc(3.5em - -2px);
        }
      } //select
      textarea {
        @include adaptive-placeholder($checkout-adaptive-placeholder-parameters--textarea);
        &:focus,
        &.active,
        &.js-label-mode {
          & + label:before {
            -webkit-transform: translateY(-1em - 0.6em) translateY(-1px * 0.5) scale(0.8, 0.8);
            transform: translateY(-1em - 0.6em) translateY(-1px * 0.5) scale(0.8, 0.8);
          }
        }
      }
    } // .adpl

    &#shipping {
      .checkout {
        @media #{$medium-down} {
          letter-spacing: inherit;
        }
        #address_form_container,
        #address-section-container {
          .form_element {
            select {
              @media #{$medium-down} {
                margin-bottom: 8px;
              }
            }
            @media #{$medium-down} {
              margin-bottom: 10px;
            }
            &.country_container,
            &.city_container {
              em {
                font-size: 15px;
                letter-spacing: 0.75px;
                @media #{$medium-down} {
                  letter-spacing: 0.05em;
                }
              }
            }
          }
          .address-container,
          .packstation-link {
            font-size: 15px;
            letter-spacing: 0.75px;
            @media #{$medium-down} {
              letter-spacing: 0.05em;
            }
          }
          .title_container {
            @media #{$medium-down} {
              margin-bottom: 5px;
            }
          }
          .phone_label_container {
            font-size: 15px;
            letter-spacing: 0.75px;
            @media #{$medium-down} {
              margin-bottom: 10px;
              letter-spacing: 0.05em;
            }
          }
          #order_status {
            @media #{$medium-down} {
              border-bottom: 1px solid #cccccc;
            }
          }
          #choose-address {
            font-size: 15px;
            letter-spacing: 0.75px;
            @media #{$medium-down} {
              letter-spacing: 0.05em;
            }
            select {
              font-size: 12px;
              letter-spacing: 1.8px;
            }
          }
          input {
            &.error[type='text'],
            &.error[type='tel'] {
              border-color: red;
            }
          }
        }
        #corporate-invoice-options {
          .form-item {
            small {
              font-size: 12px;
            }
          }
        }
        #billing_address_form_container {
          .address.fs {
            .radio.title_container {
              .title_option {
                display: inline-block;
                margin-#{$rdirection}: 10px;
                input[type='radio'] {
                  width: 15px;
                  height: 15px;
                  margin-top: 3px;
                }
              }
            }
            .country_container,
            .city-results {
              em {
                font-size: 15px;
                letter-spacing: 0.75px;
                @media #{$medium-down} {
                  letter-spacing: 0.05em;
                }
              }
            }
            .phone_label_container {
              font-size: 15px;
              letter-spacing: 0.75px;
              @media #{$medium-down} {
                letter-spacing: 0.05em;
              }
            }
          }
          input {
            &.error[type='text'] {
              border-color: red;
            }
          }
        }
        #order-summary-panel {
          .gray_background_total {
            @media #{$medium-down} {
              padding-top: 8px;
            }
            .label {
              @media #{$medium-down} {
                font-size: 16px;
              }
            }
            .value {
              @media #{$medium-down} {
                font-size: 16px;
              }
            }
          }
          #status-table {
            @media #{$medium-up} {
              font-size: 15px;
              letter-spacing: 0.75px;
            }
          }
          #shipping-information-trigger {
            display: none;
          }
        }
        .gift-card-fields {
          margin-top: 10px;
        }
        #gift-options {
          margin-bottom: 10px;
          margin-top: 20px;
          @media #{$medium-down} {
            @include swap_direction(margin, 0px);
            border-top: 1px solid $color-black;
            border-bottom: none;
          }
        }
        .gift-options-container {
          .gift {
            margin-top: 15px;
          }
        }
        .payment-type {
          @media #{$medium-down} {
            display: inline-block;
            padding-#{$ldirection}: 10px;
          }
          label {
            @media #{$medium-down} {
              line-height: 30px;
            }
            &:before,
            &:after {
              @media #{$medium-down} {
                top: 8px;
              }
            }
            img {
              height: 30px;
            }
          }
        }
        .payment-options-header {
          @media #{$medium-down} {
            padding-bottom: 3px;
          }
        }
        #links-panel {
          @media #{$medium-down} {
            @include swap_direction(padding, 15px 24px 0px);
            border-top: 1px solid $color-black;
          }
        }
        #gift-options {
          @media #{$medium-up} {
            padding-left: 0px;
          }
          .gift-options-container {
            .form_element {
              @media #{$medium-up} {
                margin-bottom: 13px;
              }
            }
          }
        }
        #create_account_form_container {
          input {
            &.error[type='text'],
            &.error[type='email'],
            &.error[type='password'] {
              border-color: red;
            }
          }
        }
        #shipping-information-trigger {
          margin: 15px 0px;
          display: block;
        }
        .expando-block {
          &.expando-block--expanded {
            .expando-block__content {
              @include swap_direction(padding, 0 0 15px);
            }
          }
          &.viewcart-panel {
            border-bottom: none;
          }
        }
      }
      .address-form {
        @media #{$medium-down} {
          padding-top: 10px;
          overflow: visible;
          &__city-container {
            float: $ldirection;
          }
        }
        .form-item {
          @media #{$medium-up} {
            padding-#{$ldirection}: 20px;
            width: 50%;
          }
          &.left,
          &.full {
            @media #{$medium-up} {
              clear: left;
              padding: 0;
            }
          }
          &.full {
            @media #{$medium-up} {
              width: 100%;
            }
          }
          &.checkboxes {
            @media #{$medium-up} {
              padding-#{$ldirection}: 0px;
            }
          }
          &.country_container,
          .city-results {
            em {
              font-size: 15px;
              letter-spacing: 0.75px;
              @media #{$medium-down} {
                letter-spacing: 0.05em;
              }
            }
          }
          .twitter-typeahead label {
            position: relative;
          }
        }
      }
      /* google auto complete dropdown on colorbox */
      .pac-container {
        z-index: 10000 !important; /* (colorbox modal z-index + 1) */
      }
      #checkout_shipping {
        .shipping-header {
          &.checkout__subtitle {
            padding: 10px 24px;
            margin: 0;
          }
        }
      }
    }
    #cboxWrapper {
      #address_form_container {
        .page-wrapper-checkout {
          .form-item {
            @media #{$medium-up} {
              padding-#{$ldirection}: 20px;
              width: 50%;
              &.left,
              &.full {
                clear: left;
                padding: 0;
              }
              &.full {
                width: 100%;
              }
            }
          }
        }
      }
    }
    &#shipping,
    #address_book {
      .address-form {
        width: 100%;
        @media #{$medium-down} {
          padding-top: 10px;
          overflow: visible;
          &__city-container {
            float: $ldirection;
          }
        }
        .form-item {
          @media #{$medium-up} {
            padding-#{$ldirection}: 20px;
            width: 50%;
            &.left,
            &.full {
              clear: left;
              padding: 0;
            }
            &.full {
              width: 100%;
            }
          }
        }
      }
      #cboxWrapper {
        .page-wrapper-checkout {
          padding-top: 0px;
          .checkout {
            padding-bottom: 0px;
            .phone_label_container {
              @media #{$medium-up} {
                font-size: 15px;
                letter-spacing: 0.75px;
              }
            }
            .country_container,
            .city_container,
            .city-results {
              em {
                font-size: 15px;
                letter-spacing: 0.75px;
                @media #{$medium-down} {
                  letter-spacing: 0.05em;
                }
              }
            }
            .city-results {
              font-size: 15px;
              letter-spacing: 0.75px;
              @media #{$medium-down} {
                letter-spacing: 0.05em;
              }
            }
            #corporate-invoice-options {
              .form-item {
                small {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
    &#viewcart {
      .recommended-products-panel input[type='submit'] {
        @media #{$medium-up} {
          max-width: 90%;
          line-height: 15px;
          word-wrap: break-word;
          display: inline-block;
          white-space: inherit;
          height: auto;
          padding: 14px 20px;
          text-align: center;
          margin-#{$ldirection}: 5%;
          margin-#{$rdirection}: 5%;
        }
      }
      #viewcart-panel {
        &.edit {
          border-top: none;
        }
        .viewcart-header {
          @include swap_direction(padding, 0 24px);
          @media #{$medium-down} {
            margin-bottom: 10px;
          }
        }
        .wp_message {
          @include swap_direction(padding, 0 24px);
        }
        .viewcart-header {
          margin-bottom: 30px;
        }
      }
      #top-viewcart-buttons {
        .continue-buttons {
          .go-shopping {
            @media #{$medium-down} {
              height: auto;
              padding: 10px;
              line-height: 3em;
            }
          }
        }
      }
      .shipform_fields {
        select {
          margin-bottom: 15px;
        }
        .delivery_option {
          display: none;
        }
      }
      .promo-panel {
        margin-bottom: 50px;
      }
      .checkout-header {
        margin-bottom: 40px;
      }
    }
    .checkout-progress-bar {
      overflow: visible;
      &__list {
        li:first-child,
        li:last-child {
          span,
          a {
            word-wrap: break-word;
            display: block;
            position: relative;
            @media #{$large-up} {
              width: 50%;
              #{$ldirection}: 25%;
            }
          }
        }
        li:first-child {
          a {
            span {
              @media #{$medium-down} {
                padding-#{$ldirection}: 10px;
              }
            }
          }
        }
      }
      .checkout-progress-bar__list-item--current {
        font-size: 13px !important;
      }
      .checkout-progress-bar__list-item--shipping,
      .checkout-progress-bar__list-item--review,
      .checkout-progress-bar__list-item--billing {
        font-size: 12px;
      }
    }
    @media #{$medium-up} {
      #payment,
      #shipping,
      #review {
        .checkout-page-title {
          display: none;
        }
      }
    }
    @media #{$medium-down} {
      #payment,
      #shipping,
      #billing,
      #review {
        .sub_form > h3,
        #review-address-info > h2,
        .checkout-header {
          display: none;
        }
      }
    }
    &#signin {
      .opc__footer {
        background-color: $color-white;
        @media #{$medium-up} {
          display: none;
        }
      }
      .checkout__new-account,
      .checkout__return-user {
        input[type='text'],
        input[type='email'],
        input[type='password'],
        input[type='submit'] {
          max-width: 100%;
          width: 100%;
        }
        @media #{$medium-up} {
          width: 50%;
        }
      }
      .checkout__return-user {
        @media #{$medium-up} {
          padding-#{$ldirection}: 30px;
          border-#{$ldirection}: 1px solid rgb(204, 204, 204);
          float: left;
        }
      }
      .checkout__new-account {
        @media #{$medium-up} {
          padding-#{$rdirection}: 30px;
          float: left;
        }
        .new-account__submit {
          @media #{$medium-up} {
            margin-#{$ldirection}: -30px;
          }
        }
      }
      .checkout {
        padding-bottom: 0px;
        @media #{$medium-up} {
          margin: 0 auto;
          float: none;
        }
      }
      #sign-in-panel {
        .error_messages {
          @media #{$medium-down} {
            padding: 5px 0px;
          }
        }
        input {
          &.error[type='text'],
          &.error[type='password'] {
            border-color: red;
          }
        }
      }
      .pg_wrapper {
        &.sign-in-page {
          min-height: 100vh;
        }
        padding-top: 0px;
      }
    }
    &#viewcart,
    &#shipping {
      #order_status {
        .subtotal {
          &.label {
            @media #{$medium-down} {
              text-align: $ldirection;
            }
          }
        }
      }
      .page-wrapper {
        .checkout {
          .checkout__content {
            .order_status {
              #checkout_shipmethod {
                @media #{$medium-down} {
                  display: block;
                }
              }
              @media #{$medium-down} {
                .shipform_fields {
                  .shipping__delivery-address {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
    &#viewcart {
      .page-wrapper {
        .checkout {
          .checkout__sidebar {
            #order-summary-panel {
              #order-status {
                td {
                  #checkout_shipmethod {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }
    // new code merged from checkout and checkout-mobile.scss

    .transaction-details {
      .transaction-item {
        @media #{$medium-up} {
          width: 50%;
        }
      }
    }
    .page-wrapper {
      .checkout {
        @media #{$medium-down} {
          @include swap_direction(margin, 0);
        }
        .checkout__content {
          @media #{$medium-up} {
            width: 65% !important;
            @include swap_direction(padding, 35px 24px 50px 24px);
          }
          .error_messages,
          #promo-message,
          #checkout_shipmethod,
          #address-section-container,
          .shipping-form-create,
          .h_newsletter,
          .email_promotions,
          .shipping-header,
          #sign-in-panel {
            @media #{$medium-down} {
              @include swap_direction(padding, 0px 24px);
            }
          }
          .checkout-table {
            @media #{$medium-down} {
              @include swap_direction(padding, 0px 18px);
            }
          }
          .order_status {
            content: '';
            display: block;
            padding: 0;
            order: 2;
            .subtotal.label {
              float: $ldirection;
              margin: 0;
              margin-bottom: 6px;
              padding-#{$ldirection}: 24px;
              width: 70%;
              line-height: 18px;
              text-align: $ldirection;
              text-transform: uppercase;
            }
            .subtotal.value {
              float: $rdirection;
              margin: 0;
              margin-bottom: 6px;
              padding-#{$rdirection}: 24px;
              width: 30%;
              font-family: 'AkzidenzGrotesk W1G';
              font-size: 12px;
              font-weight: bold;
              letter-spacing: 0.05em;
              line-height: 18px;
              text-align: $rdirection;
            }
            .gray_background_total {
              .label,
              .value {
                background: $color-lighter-gray;
                margin-bottom: 0;
                padding-top: 18px;
                padding-bottom: 18px;
              }
            }
            .gray_background_tax {
              .label,
              .value {
                background: none;
                margin-bottom: 0;
                padding-bottom: 0px;
                margin-top: 6px;
              }
            }
            #checkout_shipmethod {
              @media #{$medium-down} {
                display: none;
              }
              #delivery-options {
                .shipform_fields {
                  @media #{$medium-down} {
                    margin-bottom: 3px;
                  }
                }
                a#shipping-information-trigger {
                  display: none;
                }
              }
            }
            .checkout__panel-title {
              @media #{$medium-down} {
                margin: 0px;
                padding-bottom: 10px;
              }
            }
          }
          @media #{$medium-up} {
            .h_newsletter {
              padding: 10px 24px;
              margin: 0;
            }
          }
        }
        .checkout__sidebar {
          @media #{$medium-down} {
            padding-bottom: 0px;
          }
          @media #{$medium-up} {
            width: 35% !important;
            @include swap_direction(padding, 24px 0px);
            margin-top: 80px;
          }
          &:after {
            @media #{$medium-up} {
              width: 35% !important;
              #{$ldirection}: 65% !important;
            }
          }
          .offer-code-panel {
            @media #{$medium-up} {
              @include swap_direction(padding, 0 24px);
              border-top: 1px solid $color-light-gray;
              border-bottom: 1px solid $color-light-gray;
            }
            &__title {
              @extend %checkout-subtitle !optional;
              position: relative;
              @media #{$medium-up} {
                margin-bottom: 0;
                @include swap_direction(padding, 18px 0px);
                border-bottom: none;
              }
              .title-icon {
                background-size: auto auto;
                background-position: 0 -3269px;
                width: 53px;
                height: 53px;
                position: absolute;
                top: -3px;
                @media #{$medium-up} {
                  top: 7px !important;
                }
                #{$checkout-right}: -10px;
              }
              &.open {
                .title-icon {
                  background-size: auto auto;
                  background-position: 0 -2957px;
                  width: 53px;
                  height: 53px;
                }
              }
            } // .&__title
            .offer_code_form_container {
              @media #{$medium-up} {
                padding-bottom: 24px;
              }
              input {
                &.error[type='text'] {
                  @media #{$medium-up} {
                    border-color: red;
                  }
                }
              }
            }
            .offer_code_box {
              @media #{$medium-down} {
                @include swap_direction(padding, 0 25px);
              }
              input {
                &.error[type='text'] {
                  @media #{$medium-down} {
                    border-color: red;
                  }
                }
              }
            }
            .trans_detail_item.offer_code {
              @media #{$medium-down} {
                @include swap_direction(padding, 0 24px);
              }
            }
            input[type='submit'] {
              @media #{$medium-up} {
                width: 100% !important;
                letter-spacing: 0.07em;
                padding: 0;
              }
            }
          }
          #customer-service {
            @media #{$medium-up} {
              @include swap_direction(padding, 0 24px);
            }
            .customer-service-content {
              p {
                font-size: 15px;
                letter-spacing: 0.75px;
              }
            }
          }
          #order-summary-panel {
            @media #{$medium-up} {
              margin-top: 40px;
            }
            .order-summary-panel__title {
              @media #{$medium-up} {
                @include swap_direction(padding, 0px 24px);
                margin-bottom: 18px;
                border-bottom: none;
              }
            }
            #order-status {
              tr {
                @media #{$medium-up} {
                  line-height: 24px;
                }
                &.gray_background_total {
                  @media #{$medium-up} {
                    background-color: $color-lighter-gray;
                  }
                }
              }
              td {
                &:first-child {
                  @media #{$medium-up} {
                    padding-#{$ldirection}: 24px;
                    width: 70%;
                  }
                }
                &:last-child {
                  @media #{$medium-up} {
                    padding-#{$rdirection}: 24px;
                    padding-#{$ldirection}: 0px !important;
                    width: 30%;
                  }
                }
                &.price {
                  @media #{$medium-up} {
                    font-weight: bold;
                    font-size: 12px;
                  }
                }
                #checkout_shipmethod {
                  display: none;
                  @media #{$medium-up} {
                    padding-#{$ldirection}: 24px;
                  }
                  #delivery-options {
                    @media #{$medium-up} {
                      margin-top: 0px;
                    }
                    .shipform_fields {
                      @media #{$medium-up} {
                        float: none;
                        width: 100%;
                        margin-bottom: 3px;
                        .delivery_option label > a {
                          float: left;
                        }
                      }
                      .ship-method-home-group {
                        @media #{$medium-up} {
                          select {
                            background-color: $color-gray-cs-side;
                            text-transform: none;
                            background-position: right -6px center;
                            height: calc(3.5em - -2px);
                            border: $checkout-line--input-light-gray;
                          }
                        }
                      }
                    }
                  }
                }
              }
              p {
                @media #{$medium-up} {
                  @include swap_direction(padding, 0 24px);
                }
              }
            }
          }
          .viewcart-buttons-panel.panel {
            @media #{$medium-down} {
              @include swap_direction(padding, 20px 24px 0px);
            }
          }
        }
        #links-panel {
          @include swap_direction(padding, 0px 24px);
          @media #{$medium-down} {
            @include swap_direction(padding, 20px 24px 0px);
          }
          .links_list {
            @media #{$medium-down} {
              font-size: 15px;
            }
          }
        }
        #checkout-wrapper {
          .expando-block,
          #links-panel {
            @media #{$medium-down} {
              @include swap_direction(padding, 0px);
              @include swap_direction(margin, 0px);
            }
          }
          .checkout-buttons-container {
            @media #{$medium-down} {
              @include swap_direction(padding, 0px);
              @include swap_direction(margin, 0px);
              border-top: none;
            }
          }
        }
      }
      #review-address-info {
        @media #{$medium-up} {
          .transaction-details {
            h3.checkout__subtitle {
              float: $ldirection;
              width: 67%;
              font-size: 11px;
            }
            a.address-book__controls-link {
              font-size: 9px;
            }
          }
        }
      }
    }
    &#payment {
      .payment-options-section {
        @media #{$medium-down} {
          border-top: none;
        }
        .payment-options-header {
          @media #{$medium-down} {
            padding-bottom: 3px;
          }
        }
      }
      .payment-type {
        @media #{$medium-down} {
          display: inline-block;
          padding-#{$ldirection}: 10px;
          padding-#{$rdirection}: 35px;
        }
        label {
          @media #{$medium-down} {
            line-height: 30px;
          }
          &:before,
          &:after {
            @media #{$medium-down} {
              top: 8px;
            }
          }
          img {
            height: 30px;
          }
        }
        &-panel {
          .payment-info-panel {
            &__title {
              text-align: center;
              margin-bottom: 10px;
            }
          }
        }
      }
      #payment-other-terms {
        label {
          margin-top: 10px;
        }
      }
      #checkout_complete {
        @media #{$medium-down} {
          @include swap_direction(padding, 0 0);
        }
      }
      &.is-min-nav {
        .checkout__content {
          .checkout__panel-header {
            &.checkout__panel-title {
              padding: 0 25px;
              @media #{$medium-down} {
                font-size: 13px;
                font-weight: bold;
              }
            }
          }
          .giftwrap-block {
            @media #{$medium-down} {
              .expando-block {
                &__content {
                  span {
                    display: inline-block;
                    padding-bottom: 15px;
                    float: $ldirection;
                    width: 62%;
                  }
                }
              }
            }
          }
        }
      }
      .shipmethod-block {
        .name-col {
          @media #{$medium-down} {
            width: 60%;
          }
        }
      }
      .billing-info {
        .expando-block {
          &__content {
            .change_link {
              font-size: 11px;
            }
          }
        }
      }
    }
    &#billing {
      .checkout__content {
        @media #{$medium-down} {
          @include swap_direction(padding, 0 24px);
        }
      }
    }
    &#samples {
      .checkout {
        &.mpp {
          @media #{$medium-up} {
            margin-top: 0;
          }
        }
        .samples-top {
          @media #{$medium-up} {
            border-bottom: none;
          }
        }
        #checkout_samples {
          .samples-buttons.top {
            @media #{$medium-up} {
              margin-bottom: 20px;
            }
          }
          .sample-products {
            @media #{$medium-up} {
              border-top: 1px solid $color-light-gray;
            }
          }
        }
      }
      #samples-panel {
        .product-list {
          li.product {
            @media #{$medium-down} {
              height: auto;
              width: 37.5%;
              position: relative;
              float: $ldirection;
              @include swap_direction(margin, 0 6.25% 33px 6.25%);
              text-align: center;
              padding-bottom: 50px;
            }
            &:nth-child(even) {
              @media #{$medium-down} {
                clear: $rdirection;
              }
            }
            .product-img {
              @media #{$medium-down} {
                width: 100%;
                float: none;
                padding-#{$rdirection}: 0px;
              }
            }
            .details {
              text-align: center;
              .product-desc {
                @media #{$medium-down} {
                  display: none;
                }
                @media #{$medium-up} {
                  display: none;
                }
              }
            }
            .sample-select-button {
              bottom: 0;
              position: absolute;
              left: 0;
              right: 0;
              display: block;
              height: 45px;
              line-height: 45px;
              width: 100%;
              padding: 0;
              text-align: center;
              margin-top: 7px;
              text-decoration: none;
            }
          }
        }
      }
    }
    .page-footer {
      .footer-legal {
        padding-bottom: 75px;
      }
    }
    &#samples,
    &#viewcart {
      .opc__footer {
        background-color: $color-white;
        margin-top: 0px;
      }
      .page-header {
        .page-navigation {
          @media #{$device-tablet-up} {
            display: block;
          }
        }
        .page-utilities {
          @media #{$medium-up} {
            display: block;
          }
        }
        &.is-search {
          .page-navigation {
            @media #{$device-tablet-up} {
              display: none;
            }
          }
        }
      }
    }
  }
}

.opc__shipping,
.opc__payment {
  .checkout {
    #order-summary-panel {
      border-bottom: none;
      padding: 0;
      .expando-block {
        &.expando-block--expanded {
          .expando-block__content {
            height: auto;
          }
        }
        .expando-block__content {
          height: 0;
        }
        &.shipmethod-block {
          .expando-block__content {
            padding-bottom: 15px;
          }
        }
      }
      #status_table {
        .gray_background_total {
          .value {
            @media #{$medium-down} {
              @include swap_direction(padding, 0px);
            }
          }
        }
      }
      #order_status {
        @media #{$medium-down} {
          .checkout__panel-title {
            @include swap_direction(padding, 0 24px);
          }
        }
      }
    }
    .expando-block {
      padding: 0 !important;
      margin: 0;
      border-bottom: 1px solid $color-black;
      .checkout__panel-title {
        min-height: 50px;
        line-height: normal;
        font-size: 13px;
        letter-spacing: 0;
        font-weight: bold;
        display: flex;
        align-items: center;
      }
    }
    .expando-block__content {
      padding: 0 25px !important;
      height: 0;
      overflow: hidden;
      margin: 0;
    }
    .expando-block.expando-block--expanded {
      .expando-block__content {
        @include swap_direction(padding, 0 0 15px);
        height: auto;
        .delivery_address {
          font-size: 12px;
          font-family: $akzidenz;
          letter-spacing: 2.5px;
          padding-bottom: 15px;
        }
        .name-col {
          font-weight: bold;
          text-transform: uppercase;
          font-family: $akzidenz;
          padding-bottom: 15px;
          display: inline-block;
        }
      }
    }
    .checkout__panel-title {
      @media #{$medium-down} {
        text-transform: uppercase;
        position: relative;
        padding-bottom: 0px;
        margin: 0;
        .title-icon {
          position: absolute !important;
          float: $rdirection;
          height: 30px;
          width: 30px;
          #{$rdirection}: 10px;
          top: 0;
          &:before {
            content: '\002B';
            font-size: 39px;
            color: $color-black;
            font-weight: normal;
          }
        }
        &.open {
          .title-icon {
            &:before {
              content: '\2212';
            }
          }
        }
      }
    }
    @if $checkout_panel_header == true {
      .checkout__panel-title {
        @media #{$medium-down} {
          border-bottom: 1px solid $color-navy;
        }
      }
    }
    .payment-info-panel__title {
      text-transform: capitalize;
    }
    .checkout__panel-title-container {
      .checkout-payment_title {
        margin: 4px 0 0 0;
        font-family: 'Brandon Text Bold';
        font-weight: 400;
        line-height: 18px;
        text-indent: 0;
        padding-bottom: 5px;
        border-bottom: 0;
        background: $color-white;
        border-top: 0;
        text-align: center;
        font-size: 30px;
      }
    }
    .payment-type-panel {
      h2.checkout__panel-title {
        text-align: center;
        text-transform: uppercase;
      }
      .payment-option-container {
        @include swap_direction(padding, 40px 0 25px);
        .payment-option-block {
          .group1 span {
            span {
              @include swap_direction(padding, 11px 24px);
              width: 100%;
              font-weight: 700;
              font-size: 12px;
              text-transform: uppercase;
              display: inline-block;
              border-top: 1px solid $color-gray-light-border;
              &:last-child {
                border-bottom: 1px solid $color-gray-light-border;
              }
              input[type='radio'] {
                ~ label::before {
                  font-size: 18px;
                }
                ~ label::before,
                ~ label::after {
                  top: 10px;
                }
              }
              label {
                display: block;
                height: 26px;
                img {
                  margin: -3px 0 0 0;
                  width: 45px;
                  float: $rdirection;
                  height: 35px;
                }
                strong.text-label {
                  display: inline-block;
                  margin-top: 7px;
                  font-weight: 400;
                  font-size: 12px;
                }
              }
            }
            @if $checkout_panel_header == true {       
              span.cc-logos {
                margin-#{$ldirection}: 10px;
              }
              span {
                padding: 0;
                border: none;
                width: auto;
                float: #{$ldirection};
                img {
                  height: 18px;
                }
              }
            }
            .more-than-four-payment {
              width: 50%;
              border-right: 1px solid $color-gray-light-border;
              label {
                font-size: 8px;
              }
            }
            .other-payment {
              border-top: 1px solid $color-black;
              &:last-child {
                border-bottom: 1px solid $color-black;
              }
            }
          }
          .payment-option-header {
            @include swap_direction(padding, 20px 20px);
            border-top: 1px solid $color-black;
            font-weight: bold;
            font-size: 18px;
            img {
              float: $rdirection;
            }
            &.checkout__panel-title {
              border-bottom: none;
              font-size: 16px;
              letter-spacing: 1.5px;
            }
          }
          @if $checkout_panel_header == true {
            .payment-option-header {
              &.checkout__panel-title {
                display: none;
              }
            }
          }
        }
      }
      @if $checkout_panel_header == true {
        .payment-option-container {
          padding: 20px 0 0;
        }
      }
    }
    header {
      @include swap_direction(padding, 10px 0);
    }
    .shipping-layout-section {
      .payment-type-panel {
        .payment-option-container {
          .payment-option-header {
            @include swap_direction(padding, 20px 30px);
          }
        }
      }
    }
  }
  .co-hidden {
    display: none;
  }
  .legal_txt {
    @include swap_direction(padding, 20px 24px);
    margin: 0;
    label {
      text-transform: inherit;
      font-size: 14px;
    }
  }
}
///
///  Full panel styles
///
.panel {
  .checkout__content & {
    &.edit {
      padding-top: $cart-spacing;
      border-top: $cart-line;
      margin-top: $cart-spacing; // shared
      @media #{$medium-up} {
        margin-bottom: $cart-spacing;
        padding-bottom: $cart-spacing * 2;
        border-bottom: $cart-line;
        border-top: none; // undo mobile
      }
    }
    &.finished {
      margin-top: $cart-spacing;
      @media #{$medium-down} {
        margin-top: 0px;
      }
    }
  } // .checkout__content &
  .checkout__sidebar & {
    margin-top: $cart-spacing;
    border-top: $cart-line;
    padding-top: $cart-spacing;
    // sidebar panel, desktop
    @media #{$medium-up} {
      border-top: none;
      padding-top: 0;
    }
    @media #{$large-up} {
      margin-top: $cart-spacing * 2;
    }
  }
}
