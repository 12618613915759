$helpful: 'Nuttig (';
$nothelpful: 'Niet behulpzaam (';
$helpful-fr: 'Utile (';
$nothelpful-fr: 'Inutile (';
$filter: 'FILTER :';

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          width: 54%;
          .pr-multiselect-button {
            margin: 5px 3px 5px 0;
            min-width: 100px;
            &-leeftijd,
            &-âge {
              &:before {
                content: $filter;
              }
            }
            &-huidzorg,
            &-besoin {
              @media #{$portrait} {
                display: block;
                width: 180px;
              }
            }
          }
        }
      }
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: $helpful;
                &:after {
                  content: ')';
                }
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: $nothelpful;
                &:after {
                  content: ')';
                }
              }
            }
          }
          .pr-rd-flag-review-container {
            margin-left: 0;
            @media #{$portrait} {
              display: block;
              margin-bottom: 30px;
            }
            a {
              font-size: 11px;
            }
          }
        }
      }
    }
    .pr-review-snapshot {
      &-snippets {
        .pr-snippet-stars-reco-stars {
          .pr-snippet {
            .pr-snippet-read-and-write {
              .pr-snippet-write-review-link {
                top: 60px;
              }
            }
          }
        }
        .pr-snippet-stars-reco-reco {
          .pr-snippet-reco-to-friend {
            padding: 0 16px;
          }
        }
      }
    }
  }
}

.i18n-fr-e-BE {
  #pr-reviewdisplay {
    .p-w-r {
      .pr-review-display {
        .pr-review {
          .pr-rd-footer {
            .pr-helpful-yes {
              .pr-helpful-count {
                &:before {
                  content: $helpful-fr;
                }
              }
            }
            .pr-helpful-no {
              .pr-helpful-count {
                &:before {
                  content: $nothelpful-fr;
                }
              }
            }
          }
        }
      }
    }
  }
}
.page-footer {
  .node-elc-nodeblock {
    z-index: 99;
  }
}
