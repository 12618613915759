.address-form {
  .postal_code_container {
    .form-item {
      margin-bottom: 0;
    }
  }
}

#cboxContent {
  .address-form {
    .radio.default_ship_container {
      position: relative;
      input[type='checkbox'] {
        top: 0;
      }
    }
  }
}

#address_form_container {
  .page-wrapper-checkout {
    padding-top: 0px;
  }
}
