.customer-service--landing {
  .sidebar-page__sidebar {
    li:first-child {
      display: block; // hide customer service link on landing
    }
  }
}

.customer-service {
  & .sidebar-page__sidebar li:first-child .level-1 {
    background-image: none;
    border-bottom: 1px solid $color-light-gray;
    margin-bottom: 15px;
  }
}

.page-footer {
  .drawer-formatter__content {
    .special-offers-tray {
      .special-offers-tray__col {
        input.form-submit {
          width: 70%;
          @include swap_direction(margin, 8px 0 0);
        }
      }
    }
  }
}

.contact-form {
  .form-submit {
    &[disabled] {
      background-color: $color-dark-gray;
      cursor: not-allowed;
    }
  }
}
