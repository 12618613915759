.enhanced_delivery_page {
  .page-wrapper {
    .pg_wrapper {
      .checkout__content {
        .sub_form {
          &#newsletter-container {
            border-bottom: 1px solid $color-navy;
            border-top: 1px solid $color-navy;
            clear: both;
            margin-top: 0;
            max-width: 562px;
            padding: 13px 15px;
            .info-link-gdpr {
              margin: 0;
              padding-#{$ldirection}: 30px;
              &::before {
                #{$ldirection}: 15px;
              }
            }
            .pc_email_promo_container {
              padding: 0;
              label {
                @include swap_direction(padding, 0 0 5px 30px);
                color: $color-black;
                font-size: 12px;
                font-style: normal;
                font-weight: normal;
                letter-spacing: 0.25px;
                line-height: 1.8;
                margin-#{$ldirection}: 0;
                padding-top: 0;
                text-transform: initial;
                &::before {
                  height: 15px;
                  width: 15px;
                }
              }
              input[type='checkbox'] ~ label {
                &::before,
                &::after {
                  border: solid 1px $color-grey-lighter;
                  content: '';
                  position: absolute;
                }
                &::before {
                  #{$ldirection}: 0;
                  border-radius: 3px;
                  color: $color-grey-lighter;
                  height: 18px;
                  width: 18px;
                }
                &::after {
                  #{$ldirection}: 6px;
                  background: none;
                  border-width: 0 2px 2px 0;
                  display: block;
                  height: 11px;
                  top: 5px;
                  transform: rotate(45deg);
                  width: 5px;
                }
              }
            }
            .h_newsletter {
              border: 0;
              color: $color-navy;
              cursor: pointer;
              display: block;
              font-family: $optimatext;
              font-size: 16px;
              font-weight: normal;
              letter-spacing: 0.25px;
              line-height: 1.25;
              margin: 0;
              padding: 0;
              text-transform: uppercase;
              .title-icon {
                float: $rdirection;
                height: 16px;
                line-height: 0.3;
                margin-top: 4px;
                width: 16px;
                &::before,
                &::after {
                  background: $color-navy;
                  content: '';
                  display: block;
                  position: relative;
                  transform: translate(0);
                }
                &::before {
                  height: 2px;
                  top: 5px;
                  width: 16px;
                }
                &::after {
                  height: 16px;
                  top: 4px;
                  transform: translate(7px, -50%);
                  width: 2px;
                }
              }
              &.open {
                .title-icon {
                  &::after {
                    visibility: hidden;
                  }
                }
              }
            }
            .email_promotions {
              font-size: 12px;
              letter-spacing: 1.25px;
              margin-top: 16px;
              padding-top: 0;
              text-transform: lowercase;
              p {
                font-size: 12px;
                letter-spacing: 1.25px;
                line-height: 1.7;
                margin-bottom: 14px;
                text-transform: initial;
              }
              .info-link-gdpr {
                padding-top: 10px;
                text-transform: initial;
                &::before {
                  #{$ldirection}: 15px;
                }
              }
              .sms_promotions {
                .sms_promo_container {
                  .field {
                    height: 54px;
                    margin-top: 5px;
                  }
                  label {
                    font-size: 12px;
                    letter-spacing: 1.25px;
                    line-height: 3.5;
                    text-transform: lowercase;
                  }
                  input[type='checkbox'] ~ label {
                    &::before,
                    &::after {
                      border: solid 1px $color-grey-lighter;
                      content: '';
                      position: absolute;
                    }
                    &::before {
                      #{$ldirection}: 0;
                      border-radius: 3px;
                      color: $color-grey-lighter;
                      height: 18px;
                      width: 18px;
                    }
                    &::after {
                      #{$ldirection}: 6px;
                      background: none;
                      border-width: 0 2px 2px 0;
                      display: block;
                      height: 11px;
                      top: 5px;
                      transform: rotate(45deg);
                      width: 5px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
