$enable_cookie-img: false !default;
/* NOTIFICATIONS
 - make changes under the BRAND SPECIFICS section
---------------------------------------------- */

/* DEFAULT CONTAINER STYLE */
#bt_notification {
  .bt-bottom-right {
    position: fixed;
    border: 1px solid $color-black;
    width: 100%;
    #{$rdirection}: 0;
    bottom: 0;
    border-bottom-width: 0;
    margin-bottom: 0;
    &.bt-content {
      width: 100%;
      #{$rdirection}: 0;
    }
    @media #{$medium-up} {
      #{$rdirection}: 10px;
      width: 24.5em;
      margin-bottom: 42px;
      &.bt-content {
        #{$rdirection}: 10px;
        width: 24.5em;
      }
    }
  }
  .bt-content {
    padding: 5px;
    background-color: $color-white;
    z-index: 1000;
    font-size: 12px;
    line-height: 16px;
    color: $color-white;
    border: 1px solid $color-almost-black;
    position: fixed;
    #{$rdirection}: 0;
    bottom: 0;
    transition: all 5s;
    -webkit-transition: all 5s; /* Safari */
    .bt-close-link {
      position: absolute;
      top: 10px;
      #{$rdirection}: 10px;
    }
    .bt-pp-section {
      display: none;
    }
    .bt-cookie-section {
      padding: 10px;
      font-family: $roboto-text;
      background: $color-white;
      color: $color-black;
    }
    h2 {
      border: 0;
      font-size: 20px;
      margin-bottom: 10px;
    }
    h6 {
      border: 0;
      font-size: 20px;
      margin-bottom: 10px;
    }
    .btn-wrap {
      text-align: center;
      background: $color-black;
      margin-top: 10px;
      border: 1px solid $color-white;
      a {
        width: 100%;
        font-weight: normal;
        padding: 0 14px;
        text-align: center;
        font-size: 11px;
        height: 30px;
        line-height: 24px;
        letter-spacing: 1px;
        display: inline-block;
        cursor: pointer;
      }
    }
    a {
      color: $color-white;
      font-weight: bold;
      border: 0;
      margin-top: 10px;
      width: 100%;
      height: auto;
      &.bt-close-link {
        display: block;
        width: 15px;
        height: 15px;
        background: url('/media/images/cookies/close_x.png') 50% 50% no-repeat;
        text-indent: -9999em;
      }
    }
  }
}
/* SETTINGS
 - make changes under the BRAND SPECIFICS section
---------------------------------------------- */

/* DEFAULT CONTAINER STYLE */
#bt_settings {
  .bt-content {
    border: 2px solid $color-black;
    border-radius: 6px;
    padding: 1em;
    height: 100%;
    background-color: $color-white;
    z-index: 1001;
    font-size: 12px;
    @media #{$medium-up} {
      padding: 2em;
    }
    .bt-intro {
      .bt-intro-copy {
        p {
          margin-top: 1em;
        }
      }
      h2 {
        text-transform: none;
        color: $color-navy;
        font-size: 28px;
      }
    }
    /* TOGGLE SLIDER */
    .slide {
      @include swap_direction(margin, 20px auto);
      width: 228px;
      position: relative;
      overflow: hidden;
      @media #{$medium-up} {
        width: 525px;
      }
    }
    @if $enable_cookie-img == true {
      .slide {
        width: 270px;
        @media #{$medium-up} {
          width: 525px;
        }
      }
    }
    .slide-toggle {
      width: 230px;
      background-image: url('/media/images/cookies/m_all-off.png');
      position: relative;
      overflow: hidden;
      margin-bottom: 0;
      background-size: contain;
      @media #{$medium-up} {
        width: 525px;
        background: transparent url('/media/images/cookies/all-off.png') 50% 100% no-repeat;
      }
      li {
        cursor: pointer;
        float: $ldirection;
        width: 76px;
        height: 126px;
        @media #{$medium-up} {
          width: 175px;
          height: 149px;
        }
        list-style-type: none;
        span {
          display: none;
        }
      }
    }
    @if $enable_cookie-img == true {
      .slide-toggle {
        background: url('/media/images/cookies/m_all-off_v1.png') no-repeat;
        width: 270px;
        background-size: 100% 100%;
        @media #{$medium-up} {
          background: url('/media/images/cookies/all-off_v1.png');
          width: 525px;
          background-size: contain;
        }
        li {
          width: 135px;
          @media #{$medium-up} {
            width: 262px;
          }
        }
      }
    }
    .mask {
      position: absolute;
      overflow: hidden;
      top: 0px;
      #{$ldirection}: 0;
      width: 0;
      border-#{$rdirection}: 1px solid $color-lighter-gray;
      transition: width 1s;
      -webkit-transition: width 1s; /* Safari */
      .slide-toggle {
        background-image: url('/media/images/cookies/m_all-on.png');
        @media #{$medium-up} {
          background-image: url('/media/images/cookies/all-on.png');
        }
      }
      @if $enable_cookie-img == true {
        .slide-toggle {
          background: url('/media/images/cookies/m_all-on_v1.png') no-repeat;
          background-size: 100% 100%;
          @media #{$medium-up} {
            background-image: url('/media/images/cookies/all-on_v1.png');
            background-size: contain;
            margin: 0;
          }
        }
      }
      .drag {
        #{$rdirection}: 24px;
        bottom: 10px;
        @media #{$medium-up} {
          bottom: 12px;
          #{$rdirection}: 70px;
        }
        position: absolute;
        width: 26px;
        height: 11px;
        background: transparent url('/media/images/cookies/icon_arrow.png') 50% 100% no-repeat;
      }
      @if $enable_cookie-img == true {
        .drag {
          #{$rdirection}: 55px;
          @media #{$medium-up} {
            #{$rdirection}: 100px;
          }
        }
      }
    }
    /* PRIVACY INFORMATION */
    .bt-privacy-info {
      display: none;
      overflow: hidden;
      &.selected {
        display: block;
        &[data-userprivacy='OPTED_IN'] {
          .bt-privacy-willnot {
            display: none;
          }
        }
      }
      h3 {
        @include swap_direction(padding, 15px 1em 15px 40px);
        background-position: 10px 50%;
        background-repeat: no-repeat;
        font-size: 24px;
        background-color: $color-gray-cs-side;
      }
      div {
        width: 100%;
        float: none;
        @media #{$medium-up} {
          width: 48%;
          float: $ldirection;
        }
        margin-#{$rdirection}: 2%;
        ul {
          @include swap_direction(margin, 1em);
          list-style-type: none;
          padding-#{$ldirection}: 0;
          li {
            @include swap_direction(margin, 0.8em 0);
            padding-#{$ldirection}: 30px;
            background-position: 0 50%;
            background-repeat: no-repeat;
          }
        }
      }
      .bt-privacy-will {
        h3 {
          background-image: url('/media/images/cookies/icon_safe_lock.png');
        }
        ul {
          li {
            background-image: url('/media/images/cookies/icon_safe_thick.png');
            list-style-type: none;
          }
        }
      }
      .bt-privacy-willnot {
        h3 {
          background-image: url('/media/images/cookies/icon_unsafe_lock.png');
        }
        ul {
          li {
            background-image: url('/media/images/cookies/icon_unsafe_thick.png');
            list-style-type: none;
          }
        }
      }
    }
  }
}
/* TEMPORARY HIDE COUNTRY LABEL */
.address-form__country-container {
  .label {
    visibility: hidden;
  }
}

.customer-service-section {
  #bt_settings {
    div {
      display: inline;
    }
  }
}

.tealium {
  display: none !important;
}
