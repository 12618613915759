.enhanced_delivery_page {
  &.co_updates_enabled {
    &#payment {
      .checkout_container {
        .order-summary-panel {
          .checkout__panel-title {
            text-align: $ldirection;
          }
        }
        #checkout_complete {
          margin-top: 45px;
        }
      }
      .payment-panel {
        #payment-other-terms {
          label {
            margin-top: 13px;
          }
        }
      }
      .payment-type {
        label {
          padding-#{$ldirection}: 38px;
          &::before,
          &::after {
            border-radius: 10px;
            height: 20px;
            width: 20px;
          }
          img {
            height: 23px;
          }
        }
      }
    }
  }
  .page-wrapper {
    .pg_wrapper {
      .checkout-page-title {
        font-size: 40px;
        line-height: 1.5;
        text-align: center;
        text-transform: capitalize;
      }
      .payment-panel {
        &.finished {
          margin-top: 13px;
          .payment-properties {
            .checkout_title {
              display: none;
            }
          }
        }
        .payment-properties-header {
          &.checkout-header {
            margin-bottom: 16px;
          }
        }
        .payment-properties.radio-buttons {
          .cc-header {
            border-top: 1px solid $color-navy;
            font-family: $akzidenz;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1.25px;
            line-height: 1.67;
            margin: 0;
            padding: 18px 56px 15px;
            text-transform: uppercase;
          }
          #review-instructions,
          .payment-instructions,
          .payment-require {
            display: none;
          }
          .checkout__panel-title {
            border-bottom: 0;
            color: $color-grey-nero;
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 0.25px;
            line-height: 1.25;
            padding-bottom: 67px;
            text-align: center;
            text-transform: uppercase;
          }
        }
        #payment-type-ncc {
          border-bottom: 1px solid $color-navy;
        }
        .payment-select {
          color: $color-navy;
          display: block;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 1.25px;
          margin-#{$ldirection}: 20px;
          padding: 15px 18px;
          text-transform: uppercase;
        }
        .payment-type {
          border-top: 1px solid $color-navy;
          height: 54px;
          padding: 15px 18px;
          label {
            display: block;
            height: 30px;
            .text-label {
              font-size: 12px;
              line-height: 2.1;
            }
          }
          img {
            float: $rdirection;
          }
        }
        #payment-other-terms {
          padding-#{$ldirection}: 15px;
          label {
            font-size: 12px;
            font-weight: normal;
            letter-spacing: 1.25px;
            margin: 16px auto 58px;
            text-transform: initial;
          }
          input[type='checkbox'] ~ label {
            &::before,
            &::after {
              border: solid 1px $color-navy;
              content: '';
              position: absolute;
            }
            &::before {
              #{$ldirection}: 0;
              border-radius: 2px;
              color: $color-navy;
              height: 18px;
              width: 18px;
            }
            &::after {
              #{$ldirection}: 6px;
              background: none;
              border-width: 0 2px 2px 0;
              display: block;
              height: 11px;
              top: 5px;
              transform: rotate(45deg);
              width: 5px;
            }
          }
        }
      }
      .installopts {
        .field {
          border: 1px solid $color-navy;
          border-radius: 0;
          color: $color-light-gray;
          font-size: 12px;
          height: 54px;
          letter-spacing: 1.25px;
          margin-top: 8px;
          width: 100%;
        }
      }
      .payment-description-PAYMENT_OPTION_CARDLINK,
      .payment-description-PAYMENT_OPTION_MYBANK {
        border: 1px solid $color-navy;
        color: $color-navy;
        float: none;
        font-size: 12px;
        height: auto;
        letter-spacing: 0.25px;
        line-height: 1.7;
        margin: 20px 0 0;
        padding: 14px;
      }
      .checkout-buttons-container {
        #continue-checkout-btm .checkout-buttons {
          background: $color-navy;
          color: $color-white;
          font-size: 16px;
          font-weight: bold;
          height: auto;
          letter-spacing: 1.25px;
          line-height: 1.6;
          min-height: 48px;
          padding: 10px 0;
          width: 348px;
        }
      }
    }
  }
  .transaction-details {
    .delivery-option-edit {
      display: none;
    }
    .transaction-item {
      width: 100%;
      &.transaction-promo {
        display: none;
      }
    }
  }
  .payment-address-panel,
  .payment-cart-panel {
    display: none;
  }
}
