.spp-product__benefits,
.spp-product__reviews {
  height: auto;
}

.spp-product__reviews {
  .pr-review-snapshot-block-container {
    @media #{$device-tablet-down} {
      margin-top: 80px;
    }
  }
}
