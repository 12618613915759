.page-navigation__hotswap {
  .depth-1 {
    @media #{$medium-up} {
      &.menu-container--has-nav-tout {
        min-height: 375px;
      }
    }
  }
}

.page-navigation__supplemental a.slide__link {
  white-space: nowrap;
}

.site-banner {
  &__is-open {
    .page-header {
      @media #{$medium-up} {
        min-height: 85px;
      }
    }
  }
}
