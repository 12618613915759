#bt_notification {
  div.bt-bottom-right {
    position: fixed;
    #{$rdirection}: 10px;
    bottom: 42px;
    width: 24.5em;
    border: 1px solid $color-white;
  }
  div.bt-content {
    padding: 5px;
    background-color: $color-white;
    z-index: 10000;
    font-size: 12px;
    line-height: 16px;
    color: $color-white;
    border: 1px solid $color-almost-black;
    .bt-pp-section {
      background: #930808;
      padding: 10px;
      font-family: $roboto-text;
      border: 1px solid $color-black;
    }
    .bt-cookie-section {
      padding: 10px;
      font-family: $roboto-text;
      background: $color-white;
      color: $color-black;
    }
    h6 {
      border: 0;
      font-size: 20px;
      margin-bottom: 10px;
    }
    .btn-wrap {
      text-align: center;
      background: $color-black;
      margin-top: 10px;
      border: 1px solid $color-white;
      .btn {
        width: 100%;
        font-weight: normal;
        padding: 0 14px;
        text-align: center;
        font-size: 11px;
        height: 30px;
        line-height: 24px;
        letter-spacing: 1px;
        display: inline-block;
        font-family: $roboto-text;
      }
    }
    .bt-content__link {
      color: $color-white;
      font-weight: bold;
      border: 0;
      margin-top: 10px;
      width: 100%;
      height: auto;
    }
  }
}
/* Info popup styles for privacy policy */
.info-link-gdpr {
  cursor: pointer;
  margin-bottom: 10px;
  border: 0px;
  display: block;
  color: #9eafff;
  clear: both;
  text-decoration: underline;
  font-size: 15px;
  letter-spacing: 0.75px;
}

.tools-pages.info-link-gdpr {
  width: 100px;
  margin: 0 auto;
}

.info-icon-gdpr {
  cursor: pointer;
  background: #040a2b;
  color: #fff;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  display: block;
  text-transform: lowercase;
  @include swap_direction(padding, 1px 0 0 10px);
  line-height: 20px;
}

.info-message-txt-gdpr {
  display: none;
}

.info-msg-gdpr {
  position: absolute;
  #{$ldirection}: 0;
  top: 0;
  z-index: 10000;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #040a2b;
  max-width: 510px;
  width: 90%;
  color: #000;
  &__arrow {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    #{$ldirection}: 0px;
    border: 10px solid transparent;
    border-bottom-color: #9eafff;
    top: -10px;
    margin-top: -10px;
    &.top-right-arrow {
      left: 478px;
    }
  }
  &__txt {
    position: relative;
    span {
      cursor: pointer;
      color: #9eafff;
      text-decoration: underline #9eafff;
      border: 0px;
    }
    a {
      color: #9eafff;
      cursor: pointer;
      text-decoration: underline #9eafff;
      border: 0px;
    }
  }
  &__close {
    position: absolute;
    top: -20px;
    #{$rdirection}: -20px;
    font-size: 15px;
    width: 20px;
    height: 20px;
    text-align: center;
    cursor: pointer;
  }
}

#registration_short {
  .form-item.new-account__item.checkbox.password {
    .picker.picker-checkbox,
    .password-notice {
      float: #{$ldirection};
    }
    .password-notice {
      margin: 10px 0px 0px 10px;
    }
  }
}

.info-gdpr-right-icon {
  margin-#{$ldirection}: 10px;
}

.tools-pages.info-link-gdpr[message-in='policy-terms-msg-tools'] {
  width: auto;
}

.contact-us-page {
  .contact-form {
    .main-button-wrapper {
      padding-top: 100px;
      padding-bottom: 5px;
    }
  }
  .basic-responsive {
    &.gdpr {
      margin-top: -180px;
    }
  }
}
/* Welcome popup in common */
.welcome15-overlay.alt_layout1 {
  .email_popover {
    .email_popover__social_icons {
      .twitter {
        img {
          content: url('/media/images/pro_active_popup/thumb_twitter@2x.png');
        }
      }
      .pinterest {
        img {
          content: url('/media/images/pro_active_popup/thumb_pinterest@2x.png');
        }
      }
      .instagram {
        img {
          content: url('/media/images/pro_active_popup/thumb_instagram@2x.png');
        }
      }
      .youtube {
        img {
          content: url('/media/images/pro_active_popup/thumb_youtube@2x.png');
        }
      }
    }
  }
}

.colorbox_scroll {
  overflow-y: hidden;
}
/* GDPR Live Chat Style */
.section-chatprivacypolicy {
  .page-header,
  .page-footer {
    display: none !important;
  }
}

.gdpr-terms {
  margin-bottom: 10px;
  &__label {
    letter-spacing: 0.05em;
    text-transform: none;
  }
}

//Waitlist GDPR
@import '../../emea_base/scss/components/_waitlist.scss';
