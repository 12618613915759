.enhanced_delivery_page {
  &.page-wrapper-checkout {
    padding-top: 0;
  }
  .page-wrapper {
    margin: 0 auto;
    max-width: $xxlarge-range;
    padding-top: 80px;
    width: 100%;
    .pg-wrapper-checkout {
      .page-wrapper {
        padding-bottom: 0;
      }
    }
    .pg_wrapper {
      background: $color-gray-background;
      margin-bottom: 52px;
      max-width: none;
      padding-bottom: 0;
      .shipping {
        display: flex;
      }
      .terms-icon-container {
        background: $color-navy;
        border-radius: 50%;
        color: $color-white;
        display: none;
        float: $ldirection;
        font-size: 14px;
        height: 20px;
        line-height: 1.4;
        margin-top: 45px;
        text-align: center;
        width: 20px;
      }
      .info-link-gdpr {
        margin: 25px 60px;
        clear: none;
        color: $color-navy;
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 1.45;
        text-decoration: underline;
        text-transform: initial;
        position: inherit;
        &::before {
          #{$ldirection}: 0;
          background: url($base-theme-image-path + 'icons/info_icon.png') no-repeat;
          content: '';
          height: 20px;
          position: absolute;
          width: 20px;
        }
      }
      .checkout_container {
        background: $color-white;
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        width: 100%;
        .shipping-button {
          background: $color-navy;
          color: $color-white;
          font-size: 16px;
          font-weight: bold;
          height: auto;
          letter-spacing: 1.25px;
          line-height: 1.6;
          min-height: 48px;
          min-width: 348px;
          opacity: 0.4;
          padding: 10px 15px;
        }
        .button-enable {
          opacity: 1;
        }
        .checkout-page-title {
          font-size: 40px;
          line-height: 32px;
          text-transform: uppercase;
          text-align: center;
        }
        .error_messages {
          line-height: 1.8;
          margin: 8px auto;
          text-align: $ldirection;
          width: 100%;
        }
        .account-profile__email {
          background: $color-gray-background;
          border-top: 1px solid $color-navy;
          font-size: 12px;
          height: 54px;
          letter-spacing: 1.5px;
          margin: 40px 0 43px;
          max-width: 562px;
          padding: 19px 12px;
        }
        .adpl {
          input[type='text'] {
            &.adaptive-label-mode ~ label::before {
              transform: translateY(-25px) scale(0.8, 0.8);
            }
          }
        }
        .checkout__content {
          flex: 0 0 calc(100% - 380px);
          margin: 0;
          max-width: none;
          padding: 0 0 50px;
          width: 100% !important;
          #content_wrapper {
            margin: 0 auto 73px;
            max-width: 562px;
            position: relative;
            width: 100%;
          }
          .error_messages {
            margin: 20px 0 0;
            width: 100%;
            [id*='offer_code'],
            [id*='offer_criteria'],
            [id*='offer_deferred'] {
              display: none;
            }
          }
          .checkout-header {
            margin-bottom: 44px;
            &__title {
              font-size: 40px;
              letter-spacing: 0.25px;
              text-transform: capitalize;
            }
          }
          .submit {
            margin-top: 64px;
            max-width: 560px;
          }
          .select-container {
            &::before {
              #{$rdirection}: 15px;
              border: 5px solid transparent;
              border-top-color: $color-navy;
              content: '';
              pointer-events: none;
              position: absolute;
              top: 25px;
            }
          }
          #address-section-container {
            max-width: 100%;
            .billing-display {
              display: block !important;
            }
            .billing-address {
              .edit-address {
                #{$rdirection}: 20px;
                background-image: url('/media/images/checkout/edit_cart_icon.png');
                background-size: contain;
                bottom: 28px;
                content: '';
                float: $rdirection;
                height: 18px;
                position: relative;
                text-indent: -9999px;
                width: 18px;
              }
              .edit-address.cancel_link {
                background: none;
                font-weight: normal;
                text-indent: initial;
                width: auto;
              }
              &.edit-billing {
                .address-info {
                  border-bottom: 0;
                }
                & ~ #billing_address_form_container {
                  border-bottom: 1px solid $color-navy;
                  margin-top: 0;
                  .shipping-panel__title {
                    display: none;
                  }
                }
              }
            }
            .address-container {
              .address-info {
                border-bottom: 1px solid $color-navy;
                border-top: 1px solid $color-navy;
                color: $color-navy;
                font-size: 12px;
                letter-spacing: 1.25px;
                line-height: 1.7;
                padding: 16px;
                address {
                  font-family: $akzidenz;
                  font-style: normal;
                  text-transform: capitalize;
                }
              }
            }
            .shipping-address-form-container {
              .form-item {
                margin-bottom: 22px;
              }
            }
            .terms-container {
              margin: 20px 0;
            }
            .checkout_billing_address_under_subtitle {
              color: rgb(255, 0, 0);
              margin-bottom: 15px;
              font-size: 13px;
            }
            .shipping-panel__title {
              border-bottom: 0;
              color: $color-navy;
              font-size: 16px;
              letter-spacing: 0.25px;
              line-height: 1.25;
              margin: 19px 0 16px;
              padding: 0;
              text-transform: uppercase;
              & + fieldset.address {
                margin-top: 33px;
              }
            }
            .sub-block {
              .shipping-panel__title {
                margin-top: 40px;
              }
            }
            .address_form_container {
              padding-top: 17px;
              overflow: visible;
              &#bill_to_shipping_container {
                padding-top: 2px;
              }
              .form-item {
                &.bool-item {
                  width: auto;
                }
                .field.filled-in.js-label-mode {
                  & + label {
                    &::before {
                      transform: translateY(-27px) scale(0.8, 0.8);
                    }
                  }
                }
                label {
                  color: $color-navy;
                  letter-spacing: 0.15px;
                  margin-bottom: 0;
                  &::before {
                    color: $color-light-gray;
                    font-size: 12px;
                    letter-spacing: 1.5px;
                  }
                }
                .field {
                  &:not(:empty) + label {
                    &::before {
                      transform: translateY(-28px) scale(0.8, 0.8);
                    }
                  }
                }
              }
              .delivery_instructions {
                label {
                  &::before {
                    position: relative;
                    top: 18px;
                  }
                }
              }
              .form-item,
              .form_element {
                margin-bottom: 22px;
                padding: 0;
                width: 100%;
                .field {
                  @include swap_direction(padding, 18px 20px 19px 15px);
                  border: 1px solid $color-navy;
                  box-sizing: border-box;
                  font-size: 12px;
                  height: 54px;
                  &.country_field {
                    border: 0;
                    height: 53px;
                  }
                }
                select {
                  &.field {
                    background: none;
                    border-radius: 0;
                    color: $color-navy;
                    font-size: 12px;
                    height: 54px;
                    padding: 0 15px;
                    option {
                      color: $color-light-gray;
                    }
                  }
                }
                &.phone_2_container,
                &.phone_label_container,
                &.google_autocomplete {
                  display: none;
                }
                &.country_container {
                  border: 1px solid $color-navy;
                  font-size: 12px;
                  height: 54px;
                  line-height: 1.34;
                  padding: 17px 15px 21px;
                  label {
                    display: none;
                  }
                  em {
                    bottom: 4px;
                    color: $color-navy;
                    font-style: normal;
                    letter-spacing: 0.15px;
                    position: relative;
                  }
                }
              }
              .terms-icon-container {
                border-radius: 50%;
                background: $color-navy;
                color: $color-white;
                display: none;
                float: $ldirection;
                font-size: 14px;
                height: 20px;
                text-align: center;
                width: 20px;
              }
              .info-link-gdpr {
                @include swap_direction(margin, 52px 0 59px 30px);
                color: $color-navy;
                clear: none;
                font-size: 14px;
                letter-spacing: 0.25px;
                line-height: 1.7;
                text-decoration: underline;
                text-transform: initial;
                position: inherit;
                &::before {
                  #{$ldirection}: 0;
                  background: url($base-theme-image-path + 'icons/info_icon.png') no-repeat;
                  content: '';
                  height: 20px;
                  position: absolute;
                  width: 20px;
                }
              }
            }
            #address_form_container {
              .country_container {
                background: $color-azure-transparent;
              }
              .delivery_instructions {
                textarea {
                  height: 94px;
                }
                label {
                  height: 48px;
                  line-height: 1;
                  margin-top: 0;
                  max-width: 100%;
                  padding: 0;
                  position: absolute;
                  top: 0;
                }
              }
            }
            #bill_to_shipping_container {
              .bill-to-ship {
                input[type='checkbox'] ~ label {
                  font-size: 12px;
                  height: 20px;
                  line-height: 1.7;
                  margin-bottom: 12px;
                  padding-#{$ldirection}: 30px;
                  text-transform: capitalize;
                  &::before,
                  &::after {
                    border: solid 1px $color-navy;
                    content: '';
                    position: absolute;
                  }
                  &::before {
                    #{$ldirection}: 0;
                    border-radius: 2px;
                    color: $color-navy;
                    height: 18px;
                    width: 18px;
                  }
                  &::after {
                    #{$ldirection}: 6px;
                    background: none;
                    border-width: 0 2px 2px 0;
                    display: block;
                    height: 11px;
                    top: 5px;
                    transform: rotate(45deg);
                    width: 5px;
                  }
                }
              }
            }
            #billing_address_form_container {
              .country_container {
                &::before {
                  #{$rdirection}: 15px;
                  border: 5px solid transparent;
                  border-top-color: $color-navy;
                  content: '';
                  pointer-events: none;
                  position: absolute;
                  top: 25px;
                }
                padding: 0;
                em {
                  padding-#{$ldirection}: 15px;
                }
              }
            }
            #shipping-panel.billing-panel {
              border-bottom: 1px solid $color-navy;
              margin: 50px auto 0;
              padding-bottom: 0;
              position: relative;
              .payment-panel__title {
                border-bottom: 1px solid $color-navy;
                color: $color-grey-nero;
                font-size: 16px;
                font-weight: normal;
                letter-spacing: 0.15px;
                line-height: 1.35;
                margin-bottom: 13px;
                padding-bottom: 13px;
                text-transform: uppercase;
              }
              .address-container {
                &.billing-address {
                  color: $color-navy;
                  font-family: $akzidenz;
                  font-size: 12px;
                  letter-spacing: 1.25px;
                  line-height: 1.7;
                  text-transform: capitalize;
                }
              }
              .address_controls {
                float: $rdirection;
                .edit-address {
                  #{$rdirection}: 16px;
                  background-image: url('/media/images/checkout/edit_cart_icon.png');
                  background-size: contain;
                  display: block;
                  float: $rdirection;
                  height: 18px;
                  margin-top: 50px;
                  position: absolute;
                  text-indent: -9999px;
                  top: 0;
                  width: 18px;
                  &.cancel_link {
                    background: none;
                    font-weight: normal;
                    text-indent: initial;
                    width: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
    .page-sticky-footer.is-sticky {
      #{$ldirection}: 0;
    }
  }
  &.guest-user-panel {
    .delivery_address {
      bottom: 2px;
      position: relative;
      .co_default {
        display: none;
      }
    }
  }
}

/*
 Fix for "i" icon display for NON "enhanced_delivery_page"
 this is a copy of same sections under ".enhanced_delivery_page" with "position" fix
*/
.page-wrapper {
  .checkout {
    .terms-icon-container {
      background: $color-navy;
      border-radius: 50%;
      color: $color-white;
      display: none;
      float: $ldirection;
      font-size: 14px;
      height: 20px;
      line-height: 1.4;
      margin-top: 45px;
      text-align: center;
      width: 20px;
    }
    .info-link-gdpr {
      margin: 25px 60px;
      color: $color-navy;
      font-size: 14px;
      letter-spacing: 0.25px;
      line-height: 1.45;
      text-decoration: underline;
      text-transform: initial;
      position: relative;
      &::before {
        #{$ldirection}: -30px;
        background: url($base-theme-image-path + 'icons/info_icon.png') no-repeat;
        content: '';
        height: 20px;
        position: absolute;
        width: 20px;
      }
    }
  }
}
