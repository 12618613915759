//One Trust Implementation
.cookie-settings-ot {
  margin: 0 2em 2em;
  .optanon-show-settings-popup-wrapper {
    margin: 0 auto;
    height: 35px;
    max-width: 1024px;
    @media #{$xlarge-up} {
      padding: 0 2em;
    }
  }
}

.optanon-alert-box-wrapper {
  .optanon-alert-box-bg {
    .optanon-alert-box {
      &-body {
        @media #{$medium-up} {
          margin: 0 40px;
        }
      }
      &-button-container {
        @media #{$medium-up} {
          position: relative;
          margin: 5px;
        }
      }
    }
  }
  .optanon-alert-box-bottom-top {
    .optanon-alert-box-corner-close {
      @media #{$medium-up} {
        top: auto;
        bottom: 22px;
      }
    }
  }
}
