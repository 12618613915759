.enhanced_delivery_page {
  .page-wrapper {
    .pg_wrapper {
      .checkout_container {
        .checkout__content {
          input[type='radio'] {
            & ~ label {
              &::before,
              &::after {
                border-radius: 10px;
                height: 20px;
                width: 20px;
              }
            }
          }
          #address-section-container {
            .shipping-address-panel {
              .shipping-panel__title {
                border-bottom: 1px solid $color-navy;
                margin-bottom: 0;
                padding-bottom: 16px;
              }
            }
            .delivery_instructions {
              .field {
                height: 94px;
              }
            }
            .shipping-address-form-container,
            .billing-address-form-container {
              clear: both;
              padding-top: 0;
              input[type='text'],
              textarea {
                &.adaptive-label-mode {
                  & + label {
                    &::before {
                      transform: translateY(-29px) scale(0.8, 0.8);
                    }
                  }
                }
              }
              .delivery_instructions {
                .field {
                  height: 94px;
                }
              }
              .country_container {
                select {
                  &.field {
                    padding: 0 15px;
                  }
                }
              }
              .field {
                @include swap_direction(padding, 18px 20px 19px 15px);
                border: 1px solid $color-navy;
                font-size: 12px;
                height: 54px;
                &.country_field {
                  color: $color-navy;
                  font-size: 12px;
                  option {
                    color: $color-light-gray;
                  }
                }
              }
              label {
                &::before {
                  color: $color-light-gray;
                  font-size: 12px;
                }
              }
              select {
                border: 1px solid $color-navy;
                border-radius: 0;
                color: $color-light-gray;
                height: 54px;
              }
            }
            .billing-address-form-container {
              .first_name_container {
                input[type='text'].adaptive-label-mode {
                  & + label::before {
                    transform: translateY(-33px) scale(0.8, 0.8);
                  }
                }
              }
            }
            .shipping-address-form-container {
              border-bottom: 1px solid $color-navy;
              padding-bottom: 10px;
              .country_container {
                background: $color-azure-transparent;
                border: 1px solid $color-navy;
                font-size: 12px;
                height: 54px;
                line-height: 1.34;
                padding: 17px 15px 21px;
                em {
                  font-style: normal;
                }
              }
              .delivery_instructions {
                position: relative;
                textarea {
                  &.adaptive-label-mode + label {
                    &::before {
                      transform: translateY(-26px) scale(0.8, 0.8);
                    }
                  }
                  & + label {
                    height: auto;
                    margin-top: 0;
                    padding-top: 0;
                    position: absolute;
                    top: 17px;
                  }
                }
              }
            }
            .address_controls {
              max-width: 562px;
              width: 100%;
              #choose-address {
                margin-top: 0;
                width: 100%;
                .add_new_address {
                  border-bottom: 1px solid $color-navy;
                  display: block;
                  margin-top: 13px;
                  padding-bottom: 4px;
                  .shipping-address-form-container {
                    margin-top: 38px;
                  }
                }
                .add-new-address {
                  border-bottom: 1px solid $color-navy;
                  height: 54px;
                  padding: 16px 0 18px;
                  position: relative;
                  width: 100%;
                  &.open {
                    border-bottom: 0;
                  }
                  .add-address {
                    @include swap_direction(margin, 0 0 0 14px);
                    display: block;
                    font-size: 20px;
                    font-weight: bold;
                    letter-spacing: 0.25px;
                    text-decoration: none;
                    &::after,
                    &::before {
                      #{$ldirection}: 14px;
                      border: 2px solid $color-white;
                      border-radius: 10px;
                      box-shadow: 0 0 0 1px $color-light-gray;
                      content: '';
                      height: 20px;
                      position: absolute;
                      top: 18px;
                      width: 20px;
                    }
                    &::after {
                      opacity: 0;
                      transform: scale(0);
                    }
                    &.add-radio-checked {
                      &::after {
                        background: $color-navy;
                        opacity: 1;
                        transform: none;
                      }
                    }
                  }
                  .add-address-label {
                    #{$ldirection}: 52px;
                    bottom: 16px;
                    font-size: 12px;
                    font-weight: bold;
                    letter-spacing: 1.25px;
                    line-height: 1.7;
                    position: absolute;
                    text-transform: uppercase;
                  }
                }
                .shipping-address-form-container {
                  border-bottom: 1px solid $color-navy;
                }
              }
              .shipping-address-container {
                border-bottom: 1px solid $color-navy;
                .edit-address-container {
                  label {
                    &::after,
                    &::before {
                      top: 2px;
                    }
                  }
                }
                label {
                  width: 100%;
                  &::after,
                  &::before {
                    #{$ldirection}: 15px;
                    top: 18px;
                  }
                  .change-link-btn {
                    background: none;
                  }
                  .change_link.cancel_link {
                    background: none;
                    font-weight: normal;
                    width: auto;
                  }
                  .change_link,
                  .address-book__controls-link {
                    background-image: url('/media/images/checkout/edit_cart_icon.png');
                    background-size: contain;
                    content: '';
                    float: $rdirection;
                    height: 18px;
                    margin: 15px;
                    position: relative;
                    width: 18px;
                    z-index: 1;
                  }
                  .delivery_address {
                    #{$ldirection}: 16px;
                    font-family: $akzidenz;
                    font-size: 12px;
                    letter-spacing: 1.25px;
                    margin-top: 1px;
                    padding: 15px;
                    position: relative;
                    text-transform: capitalize;
                    .co_default {
                      font-weight: bold;
                      margin-bottom: 4px;
                      text-transform: uppercase;
                    }
                  }
                }
                .edit-shipping-address {
                  input[type='text'] {
                    color: $color-navy;
                    font-size: 12px;
                    height: 54px;
                  }
                  input[type='text'],
                  textarea {
                    @include swap_direction(padding, 17px 20px 13px 15px);
                    border: 1px solid $color-navy;
                    &.js-label-mode {
                      & + label {
                        &::before {
                          transform: translateY(-29px) scale(0.8, 0.8);
                        }
                      }
                    }
                  }
                  .delivery_instructions {
                    position: relative;
                    .field {
                      &.adaptive-label-mode {
                        & + label {
                          &::before {
                            transform: translateY(-28px) scale(0.8, 0.8);
                          }
                        }
                      }
                      & + label {
                        height: auto;
                        margin-top: 0;
                        padding-top: 0;
                        position: absolute;
                        top: 17px;
                      }
                    }
                  }
                  select {
                    border: 1px solid $color-navy;
                    border-radius: 0;
                    height: 54px;
                  }
                  .form-item {
                    label {
                      &::before {
                        #{$ldirection}: 0;
                        color: $color-light-gray;
                        font-size: 12px;
                        margin-#{$ldirection}: 14px;
                        padding: 0;
                        position: relative;
                        top: 3px;
                      }
                    }
                  }
                  #google_locationField,
                  .phone_label_container {
                    display: none;
                  }
                  .country_container {
                    background: $color-azure-transparent;
                    border: 1px solid $color-navy;
                    box-sizing: border-box;
                    font-size: 12px;
                    height: 54px;
                    line-height: 1.34;
                    padding: 17px 15px 21px;
                    label {
                      display: none;
                    }
                    em {
                      font-size: 12px;
                      font-style: normal;
                      line-height: 1.5;
                    }
                  }
                  .address {
                    position: relative;
                    .form-item {
                      margin-bottom: 22px;
                      &.delivery_instructions {
                        margin-bottom: 14px;
                      }
                    }
                    .default_ship_container {
                      line-height: 1.4;
                      input[type='checkbox'] ~ label {
                        &::before {
                          #{$ldirection}: 0;
                          border: solid 1px $color-navy;
                          border-radius: 2px;
                          content: '';
                          color: $color-navy;
                          height: 18px;
                          position: absolute;
                          top: 1px;
                          width: 18px;
                        }
                        &::after {
                          #{$ldirection}: 6px;
                          background: none;
                          border: solid $color-navy;
                          border-width: 0 2px 2px 0;
                          content: '';
                          display: block;
                          height: 11px;
                          position: absolute;
                          top: 4px;
                          transform: rotate(45deg);
                          width: 5px;
                        }
                      }
                    }
                  }
                  .continue-save-btn {
                    margin: 21px 0 48px;
                    text-align: $rdirection;
                    .form-submit {
                      height: 48px;
                      max-width: 348px;
                      width: 100%;
                    }
                  }
                  .title_container {
                    span {
                      display: flex;
                      .title_option {
                        margin-#{$rdirection}: 20px;
                      }
                    }
                  }
                }
              }
            }
            #shipping-panel {
              &.billing-panel {
                padding-top: 0;
                .address-container.billing-address {
                  @include swap_direction(padding, 0 0 15px 14px);
                  margin: 0;
                  .billing-address-content {
                    padding: 0 14px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &#shipping {
    #address_form_container {
      .form_element.country_container {
        padding-top: 21px;
        em {
          font-size: 12px;
          font-style: normal;
          line-height: 1.43;
        }
      }
    }
  }
}
