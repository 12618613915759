#cboxClose {
  #{$rdirection}: 25px;
  top: 25px;
}

#cboxContent {
  @include swap_direction(padding, 30px);
}

#colorbox.mpp-compare__container #cboxClose {
  #{$rdirection}: 25px !important;
}
