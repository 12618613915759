.enhanced_delivery_page {
  &#payment {
    .checkout-progress-bar__list {
      &-item {
        &--review {
          &::after {
            background: $color-navy;
          }
        }
      }
      li:last-child .progress_bar_lable {
        line-height: 1.6;
        width: 100%;
      }
    }
  }
  .pg_wrapper {
    .checkout__content {
      .checkout-progress-bar {
        margin: 38px 12px;
        max-width: 550px;
        width: 100%;
        &__list {
          overflow: visible;
          margin: 0 auto;
          padding: 0 33px;
        }
        &__list-item {
          #{$ldirection}: 0;
          font-size: 12px;
          letter-spacing: 0.25px;
          line-height: 2;
          pointer-events: none;
          text-transform: capitalize;
          &--shipping,
          &--review,
          &--billing {
            width: 100%;
          }
          &--shipping {
            max-width: 150px;
          }
          &--review {
            max-width: 160px;
          }
          &--billing {
            max-width: 173px;
          }
          .progress_bar_lable,
          a {
            #{$ldirection}: 0;
            pointer-events: none;
            width: 100%;
          }
          &::before {
            background: $color-light-gray;
            height: 12px;
            width: 12px;
          }
          &::after {
            top: 5px;
            z-index: 1;
          }
        }
        .checkout-progress-bar__list-item--complete {
          &::before {
            background: $color-navy;
            border: $color-navy;
            bottom: 3px;
            height: 16px;
            margin-bottom: 0;
            position: relative;
            width: 16px;
            z-index: 10;
          }
          a {
            color: $color-navy;
          }
        }
      }
    }
  }
}
