@mixin co_updates_review {
  .co_updates_enabled {
    &#review.is-min-nav,
    &#payment.is-min-nav {
      .checkout {
        @media #{$medium-down} {
          @include swap_direction(margin, 0);
        }
        &__content {
          .error_messages,
          #promo-message,
          .checkout__panel-title,
          .checkout-table {
            @media #{$medium-down} {
              @include swap_direction(padding, 0px 24px);
            }
            @media #{$medium-up} {
              padding-#{$ldirection}: 0px;
            }
          }
          .payment-option-container {
            .checkout__panel-title {
              @media #{$medium-down} {
                @include swap_direction(padding, 20px 45px 20px);
                text-transform: uppercase;
              }
            }
          }
          .checkout__panel-header {
            @include swap_direction(padding, 15px 24px);
          }
          display: flex;
          flex-direction: column;
          #review-address-info {
            .shipping-panel__title {
              padding-#{$ldirection}: 0px;
            }
            .edit-btn {
              @extend %checkout-link--edit !optional;
            }
            .transaction-item {
              position: relative;
              border-bottom: 1px solid $color-light-gray;
              padding: 18px 24px;
              @media #{$medium-up} {
                padding-#{$ldirection}: 0px;
              }
              dt {
                border-bottom: none;
                margin-bottom: 0px;
                padding-bottom: 0px;
              }
              dl {
                margin-#{$ldirection}: 0px;
              }
              @media #{$medium-up} {
                width: 100%;
              }
            }
            .address-book__controls-link {
              position: absolute;
              top: $checkout-spacing--small;
              right: $checkout-spacing--extra-large;
              bottom: initial; // Overwrite global rule for edit buttons;
              margin: $checkout-spacing--medium 0;
              padding: 0;
              height: initial;
              border: none;
              border-bottom: 2px solid $color-navy;
              line-height: 1.5em;
              z-index: 1;
              text-decoration: none;
              font-size: 12px;
              text-align: center;
              @media #{$large-up} {
                width: 135px;
                border: 1px solid $color-navy;
                line-height: 40px;
                height: 40px;
                right: $checkout-spacing--medium;
              }
            }
          }
          .checkout-table {
            order: 3;
            border-top: 1px solid $color-light-gray;
            .cart-item {
              border-bottom: none;
              &__total {
                @media #{$medium-down} {
                  top: inherit;
                }
              }
            }
          }
          #checkout_review {
            order: 4;
          }
          .offer-code-panel {
            @include swap_direction(padding-top, 0px);
            border-top: 1px solid $color-black;
            order: 5;
            &__title {
              @media #{$medium-down} {
                padding-#{$ldirection}: 0px;
                margin-bottom: 18px !important;
                position: relative;
              }
              .title-icon {
                background-image: url('/media/images/global/sprites-s38a9885fce.png');
                background-repeat: no-repeat;
                background-size: auto auto;
                background-position: 0 -3269px;
                width: 53px;
                height: 53px;
                position: absolute;
                top: -3px;
                #{$checkout-right}: -10px;
              }
              &.open {
                .title-icon {
                  background-image: url'/media/images/global/sprites-s38a9885fce.png';
                  background-repeat: no-repeat;
                  background-size: auto auto;
                  background-position: 0 -2957px;
                  width: 53px;
                  height: 53px;
                }
              }
            }
            input[type='text'] {
              &.error {
                border-color: #ff0000;
              }
            }
          }
          #links-panel {
            order: 6;
          }
          .checkout__panel-title {
            @include swap_direction(margin, 0);
          }
        }
      }
      .gray_background {
        background: $color-lighter-gray;
      }
      #review-panel {
        border-top: none;
      }
      .offer_code_box {
        @include swap_direction(padding, 0 24px);
        margin-bottom: 24px;
      }
    }
    &#review {
      .page-wrapper {
        .page-footer {
          padding-top: 30px;
        }
      }
    }
  }
}
