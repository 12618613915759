#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-rd-flag-review-container {
            margin-left: 0;
            @media #{$portrait} {
              display: block;
              margin-bottom: 30px;
            }
          }
          .pr-flag-review-thankyou {
            text-transform: capitalize;
          }
        }
      }
    }
    .pr-review-snapshot {
      &-snippets {
        .pr-snippet-stars-reco-stars {
          .pr-snippet {
            .pr-snippet-read-and-write {
              .pr-snippet-write-review-link {
                @media #{$portrait} {
                  top: 65px;
                }
              }
            }
          }
        }
      }
    }
    .pr-review-snapshot-block-container {
      margin-top: 10px;
    }
    .pr-media-modal {
      @media #{$portrait} {
        top: 20px;
      }
    }
  }
}

.write_a_review {
  &__container {
    #pr-write {
      .p-w-r {
        .tag-custom {
          span {
            color: $color-white;
          }
        }
      }
    }
  }
}
