@if $password_strengthen {
  .password-field {
    &__info {
      #password_reset & {
        @media #{$medium-up} {
          #{$ldirection}: 45%;
        }
      }
      #confirm & {
        @media #{$medium-up} {
          #{$rdirection}: -70%;
          top: -15px;
          width: 65%;
        }
      }
    }
  }
  .password-update-overlay {
    .profile-password-update {
      &__fieldset {
        .form-item input {
          width: 100%;
        }
      }
      &__button {
        border: 1px solid $color-light-gray;
        border-color: $color-light-gray;
      }
      &__rules {
        li {
          display: flex;
        }
      }
    }
    #cboxLoadedContent {
      height: auto !important;
    }
  }
  #registration {
    .profile-info__change-pwd-link {
      padding-top: 10px;
    }
    &.device-mobile {
      #colorbox {
        &.password-update-overlay {
          height: 580px !important;
          top: 200px !important;
          position: absolute !important;
        }
      }
      .password-update-overlay {
        .profile-password-update {
          &__rules {
            li {
              font-size: 10px;
              &:before {
                margin-#{$rdirection}: 0;
              }
            }
          }
        }
      }
    }
  }
}
