.enhanced_delivery_page {
  &#shipping {
    #delivery-options-group {
      .radio-wrapper {
        display: flex;
        justify-content: center;
        margin-#{$rdirection}: 0;
        padding: 25px 0 20px;
        width: 100%;
        &:first-child {
          margin-#{$rdirection}: 24px;
        }
        label {
          margin: 0;
        }
      }
    }
  }
  .page-wrapper {
    .pg_wrapper {
      .checkout_container {
        .checkout__content {
          #delivery-options {
            &-group {
              .delivery_option {
                border: 1px solid $color-navy;
                height: 96px;
                margin-#{$rdirection}: 10px;
                max-width: 269px;
                opacity: 0.4;
                text-align: center;
                &.checked {
                  opacity: 1;
                }
                label {
                  color: $color-navy;
                  font-size: 14px;
                  font-family: $optimatext;
                  font-weight: normal;
                  letter-spacing: 0.25px;
                  line-height: 4.2;
                  margin: 33px 0 0;
                  padding: 0;
                  &::before,
                  &::after {
                    display: none;
                  }
                  .home-delivery-method-icon,
                  .pickpoint-delivery-method-icon {
                    float: $ldirection;
                    margin-top: 8px;
                    width: 100%;
                    &::before {
                      background-size: contain;
                      content: '';
                      height: 24px;
                      position: absolute;
                      top: -5px;
                      width: 24px;
                    }
                  }
                  .home-delivery-method-icon {
                    &::before {
                      #{$ldirection}: 19px;
                      background-image: url('/media/images/checkout/home_cc_icon.png');
                    }
                  }
                  .pickpoint-delivery-method-icon {
                    &::before {
                      #{$rdirection}: auto;
                      background-image: url('/media/images/checkout/pickpoint-icon.png');
                      background-position: center;
                      border-bottom: 1px solid;
                    }
                  }
                }
              }
            }
            .ship-method-wrapper {
              .delivery-title {
                font-family: $optimatext;
                font-size: 16px;
                letter-spacing: 0.25px;
                line-height: 1.25;
                margin-top: 36px;
                text-transform: uppercase;
              }
            }
            .delivery-options {
              @include swap_direction(padding, 17px 13px 17px 40px);
              border: 1px solid $color-navy;
              font-size: 12px;
              letter-spacing: 1.5px;
              margin-bottom: 16px;
              max-width: 562px;
              position: relative;
              text-transform: uppercase;
              width: 100%;
              input[type='radio'] {
                #{$ldirection}: 17px;
                cursor: pointer;
                opacity: 0;
              }
              &-field {
                #{$ldirection}: 15px;
                pointer-events: none;
                position: absolute;
                top: 13px;
              }
            }
          }
        }
      }
    }
  }
}
