@if $password_strengthen {
  .password-field {
    position: relative;
    &__info {
      display: flex;
      width: 100%;
      background-color: $color-white;
      z-index: 99;
      margin-top: 5px;
      &::before {
        #signin &,
        #confirm &,
        #password_reset & {
          @media #{$medium-up} {
            content: '';
            position: absolute;
            top: 43%;
            #{$rdirection}: 100%;
            border: 5px solid;
            border-color: transparent $color-dark-gray transparent transparent;
          }
        }
        #signin & {
          @media #{$medium-up} {
            #{$ldirection}: 100%;
            border-color: transparent transparent transparent $color-dark-gray;
          }
        }
      }
      #confirm & {
        @media #{$medium-up} {
          #{$rdirection}: 20%;
          top: -30px;
          width: 27%;
        }
      }
      #password_reset & {
        @media #{$medium-up} {
          top: -32px;
          width: 15%;
          #{$ldirection}: 34%;
        }
      }
      #signin &,
      #confirm &,
      #password_reset & {
        @media #{$medium-up} {
          position: absolute;
          border: 1px solid $color-black;
        }
      }
      #signin & {
        @media #{$medium-up} {
          width: 36%;
          #{$rdirection}: 101%;
          top: -40px;
        }
      }
    }
    &__rules {
      column-count: 2;
      .registration-page &,
      #confirm &,
      #password_reset &,
      #signin & {
        @media #{$medium-up} {
          column-count: 1;
        }
      }
      padding: 10px;
      font-size: 12px;
      list-style: none;
      > li {
        padding: 3px 0;
        display: flex;
        align-items: center;
        color: $color-dark-gray;
        transition: 0.2s;
        white-space: nowrap;
        &::before {
          padding-#{$rdirection}: 6px;
          content: $cross;
          display: inline-block;
          color: $color-red;
          font-size: 1em;
          line-height: 0;
          transition: 0.2s;
        }
        &.pass {
          color: $color-green;
          &::before {
            content: $tick;
            color: $color-green;
          }
        }
      }
    }
  }
  .password-request-page {
    &__email-field {
      font-weight: bold;
    }
  }
  .sign-in-page {
    .form-item {
      &.password-field {
        overflow: visible;
      }
    }
    &__registration-options {
      #signin & {
        @media #{$medium-up} {
          margin-top: 60px;
        }
      }
    }
  }
  .loyalty-offer-overlay {
    .password-field {
      margin-bottom: 60px;
      &__info {
        display: flex;
        background: none;
        @media #{$medium-up} {
          position: absolute;
          top: 30px;
        }
      }
      &__rules {
        li {
          color: $color-white;
        }
      }
    }
  }
  .signin-overlay-wrapper {
    .password-field {
      &__info {
        display: flex;
      }
    }
  }
  .registration-page {
    &__reset-password-link {
      margin-top: 5px;
    }
  }
  .password-update-overlay {
    .profile-password-update {
      &__body {
        position: relative;
      }
      &__info {
        display: flex;
      }
      &__rules {
        margin: 10px 0;
        column-count: 2;
        li {
          padding: 3px 0;
          display: inline-block;
          color: $color-black;
          &:before {
            margin-#{$rdirection}: 6px;
            content: $cross;
            display: inline-block;
            color: $color-red;
            font-size: 1em;
            line-height: 0;
          }
          &.pass {
            color: $color-green;
            &:before {
              content: $tick;
              color: $color-green;
            }
          }
        }
      }
      &__fieldset {
        margin-top: 12px;
      }
      &__meter-status {
        font-weight: bold;
      }
      &__meter {
        height: 8px;
        border-radius: 4px;
        &--container {
          margin-top: 6px;
          background-color: $color-light-gray;
        }
        &--progress {
          background-color: $color-light-gray;
          transition: all 0.3s linear;
        }
      }
      &__meter-wrapper {
        .profile-password-update {
          &__strength-list {
            display: inline-block;
            li {
              display: none;
              font-style: italic;
            }
          }
        }
        &.weak,
        &.no-score {
          .profile-password-update {
            &__strength-list {
              .no-score {
                display: inline-block;
              }
            }
          }
        }
        &.bad {
          .profile-password-update {
            &__meter {
              &--progress {
                background-color: $color-red;
                width: 25%;
              }
            }
            &__strength-list {
              .bad {
                display: inline-block;
              }
            }
          }
        }
        &.average {
          .profile-password-update {
            &__meter {
              &--progress {
                background-color: $color-average;
                width: 50%;
              }
            }
            &__strength-list {
              .average {
                display: inline-block;
              }
            }
          }
        }
        &.good {
          .profile-password-update {
            &__meter {
              &--progress {
                background-color: $color-good;
                width: 75%;
              }
            }
            &__strength-list {
              .good {
                display: inline-block;
              }
            }
          }
        }
        &.strong {
          .profile-password-update {
            &__meter {
              &--progress {
                background-color: $color-green;
                width: 100%;
              }
            }
            &__strength-list {
              .strong {
                display: inline-block;
              }
            }
          }
        }
      }
      &__action {
        display: flex;
        width: 100%;
        margin: 20px 0;
        .form-submit {
          &:disabled,
          &.disabled {
            color: $color-light-gray;
            background-color: $color-white;
            cursor: not-allowed;
          }
        }
      }
      &__button {
        flex-grow: 1;
        height: 40px;
        &:first-child {
          margin-#{$rdirection}: 10px;
        }
      }
      &__loader {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 3;
        display: none;
        background: $color-white;
        opacity: 0.5;
        &.show {
          display: block;
        }
      }
      &__loading {
        top: 20%;
        #{$ldirection}: 45%;
        position: absolute;
      }
      .error_messages {
        width: auto;
        word-wrap: break-word;
        color: $color-red;
      }
    }
    &#colorbox {
      #cboxClose {
        #{$rdirection}: 20px;
      }
    }
  }
}
