// Click Collect

%click-collect-1 {
  width: 32%;
  font-size: inherit;
  margin-right: 2px;
  float: left;
  clear: none;
}

%click-collect-2 {
  height: 93.5%;
  overflow-y: scroll;
  background-color: #fff;
  font-family: 12px/14px 'AkzidenzGrotesk W1G';
}

%click-collect-3 {
  cursor: pointer;
  margin: 0;
  overflow: hidden;
  padding: 5px;
  border: 1px solid #ededed;
  color: #000;
  background: #fff;
  transition: padding 0.5s, background 0.5s;
}

%click-collect-4 {
  padding: 5px;
  background: #dcdbdb;
  border: 1px solid #000;
}

%click-collect-5 {
  float: left;
  width: 100px;
  margin: 0 1em 0 0;
}

%click-collect-6 {
  font-weight: bold;
  font-size: 14px;
  border: 0;
  padding: 0.5em 0;
  margin-bottom: 10px;
}

%click-collect-7 {
  clear: both;
  margin-top: 1em;
  border: none;
  background: none;
  width: 100%;
  padding: 0;
}

%click-collect-8 {
  padding: 6px;
  height: 29px;
  width: 29px;
  text-align: center;
  cursor: pointer;
}

%click-collect-9 {
  top: 55px;
  bottom: 0;
  display: block;
  position: absolute;
  left: -275px;
  width: 300px;
  padding-#{$rdirection}: 24px;
  z-index: 2;
  transition: left 0.5s;
  [dir='rtl'] & {
    left: inherit;
    right: -275px;
    transition: right 0.5s;
  }
}

%click-collect-10 {
  width: 288px;
  padding-top: 5px;
  height: 40px;
  background-color: #fff;
}

%click-collect-11 {
  display: block;
  position: absolute;
  right: 6px;
  [dir='rtl'] & {
    left: 0;
  }
  top: 16px;
  text-decoration: none;
  z-index: 3;
  margin: 0;
  background: none;
  width: 13px;
  height: auto;
  text-indent: 100%;
}

%click-collect-12 {
  top: 7px !important;
  background: #fff url(/media/export/images/click_collect/map_mobile_panel_close.jpg) no-repeat 0 0;
  text-indent: 100%;
}

%click-collect-13 {
  padding: 0.7em 3px;
  line-height: 1.26em;
  font: 12px/14px 'AkzidenzGrotesk W1G';
}

.gm-style {
  p {
    font: 12px/14px 'AkzidenzGrotesk W1G';
    font-weight: 400;
    text-decoration: none;
    margin: 5px 0px 0px 0px;
  }
}

.map-container {
  .map {
    height: 300px;
  }
  .address-map-search-panel {
    .search-box {
      overflow: hidden;
      label {
        display: block;
        font-size: inherit;
        margin: 0 0 1em;
        font-weight: bold;
      }
    }
    .search-box-field {
      width: 100%;
      font-size: inherit;
      margin: 0;
    }
    .search-box-submit {
      @extend %click-collect-1;
      margin-left: 0;
    }
    .search-box-toggle {
      @extend %click-collect-1;
      margin-left: 0;
    }
    .search-box-geolocate {
      @extend %click-collect-1;
    }
    .search-box-cancel {
      @extend %click-collect-1;
    }
    .status-bar-wrapper {
      width: 80%;
      margin-left: 10%;
      .status-bar .message {
        padding: 0.5em;
        border: 1px solid #aaa;
        box-shadow: 0 2px 4px #333;
        &:first-child {
          border-radius: 5px 5px 0 0;
        }
        &:last-child {
          border-radius: 0 0 5px 5px;
        }
        .close {
          float: right;
          a {
            text-decoration: none;
            color: inherit;
          }
        }
        &.error {
          background: #fcc;
          border-color: #a66;
        }
        &.warning {
          border-color: #aa6;
        }
        &.success {
          background: #cfc;
          border-color: #6a6;
        }
        &.information {
          background: #bdf;
          border-color: #68a;
        }
      }
    }
  }
  .address-map-directions-panel {
    display: none;
    .locations {
      @extend %click-collect-2;
    }
    .location {
      @extend %click-collect-3;
      p {
        margin: 0 0 0.5em;
      }
      .image {
        @extend %click-collect-5;
        img {
          width: 100%;
        }
      }
      .distance {
        float: right;
        color: #999;
        .uom {
          text-transform: lowercase;
        }
      }
      .location_description {
        font-style: italic;
      }
      .title span {
        @extend %click-collect-6;
      }
      div.button {
        @extend %click-collect-7;
      }
    }
  }
  .address-map-location-panel {
    display: none;
    .locations {
      @extend %click-collect-2;
      .messages {
        background: transparent;
        color: white;
        padding: 0px;
        border: 0;
        .message {
          background: #9eafff;
          color: #fff;
          padding: 5px;
          border: 1px solid #6b85ff;
        }
      }
    }
    .location {
      @extend %click-collect-3;
      &:hover {
        @extend %click-collect-4;
      }
      &.selected {
        @extend %click-collect-4;
      }
      p {
        margin: 0 0 0.5em;
        font: 12px/14px 'AkzidenzGrotesk W1G';
        font-weight: 400;
        text-decoration: none;
      }
      .image {
        @extend %click-collect-5;
        img {
          width: 100%;
        }
      }
      .distance {
        float: right;
        color: #999;
        .uom {
          text-transform: lowercase;
        }
      }
      .location_description {
        font-style: italic;
      }
      .title {
        span {
          @extend %click-collect-6;
        }
        .distance {
          display: none;
        }
      }
      div.button {
        @extend %click-collect-7;
      }
    }
    .map .location {
      min-width: 200px;
    }
  }
  .map-info-window {
    border: 1px solid #999;
    padding: 10px 5px;
    background-color: #fff;
    width: 290px !important;
    .show-access-point {
      display: none;
    }
    .location {
      width: 100%;
      padding: 0 5px;
      .title {
        padding-bottom: 5px;
        span {
          font-weight: bold;
        }
      }
    }
    .title .distance {
      display: none;
    }
  }
  .directions {
    background: #fff;
    color: #fff;
    overflow-y: scroll;
    max-height: 100%;
    height: 93.5%;
    .button {
      margin-top: 30px;
      width: 256px;
      background-color: #040a2b;
      color: #fff;
    }
  }
}

.map-info-window {
  .button {
    overflow: hidden;
    line-height: 19px;
    height: auto;
    a {
      background: #040a2b;
      color: #fff;
      cursor: pointer;
      font-family: inherit;
      font-size: 12px;
      font-weight: normal;
      margin-bottom: 3px;
      padding: 1px 0px;
      text-align: left;
      text-transform: uppercase;
      white-space: nowrap;
      letter-spacing: 0;
      margin-right: 0;
      float: left;
      border: 0;
      height: 20px;
      width: 48%;
      text-align: center;
      &.select-access-point {
        margin-right: 3px;
      }
    }
  }
  .location {
    div.button {
      clear: both;
      margin-top: 1em;
      border: none;
      background: none;
      width: 100%;
      padding: 0;
    }
    .image img {
      max-width: 100% !important;
    }
  }
}

.click_and_collect_map {
  width: 100%;
  position: fixed;
  top: 0;
  .close-container {
    display: none;
  }
  .overlay-content {
    height: 100%;
    width: 100%;
    position: relative;
    .map-container {
      height: 100%;
      position: relative;
      overflow: hidden;
      .address-map-search-panel {
        position: absolute;
        top: 0;
        z-index: 1;
        margin-left: 30px;
        margin-top: 5px;
        border-top: 0;
        .status-bar-wrapper {
          position: fixed;
          bottom: 20px;
          left: 50%;
          width: 30em;
          margin-left: -15em;
          .status-bar .message {
            border-top: 0;
            &:first-child {
              border-radius: 0;
            }
          }
        }
        .search-box-field {
          float: left;
          [dir='rtl'] & {
            float: right;
          }
          width: 400px;
          padding: 0 5px;
          font-size: 17px;
          height: 26px;
        }
        .search-box-submit {
          width: 50px;
          @extend %click-collect-8;
          background: #040a2b url(/media/export/images/click_collect/map_search.jpg) no-repeat center center;
          .loading span:before {
            content: '';
          }
        }
        .search-box-toggle {
          width: 50px;
          @extend %click-collect-8;
          .loading span:before {
            content: '';
          }
        }
        .search-box-geolocate {
          width: 50px;
        }
        .search-box-cancel {
          width: 50px;
        }
      }
      .search-box-cancel {
        @extend %click-collect-8;
        position: absolute;
        top: 0;
        right: 0.5em;
        [dir='rtl'] & {
          right: inherit;
          left: 0.5em;
        }
        margin: 5px 0 0;
        z-index: 1;
        border: 1px solid #040a2b;
        background: #040a2b url(/media/export/images/click_collect/map_close.jpg) no-repeat center center;
        .loading span:before {
          content: '';
        }
      }
      .search-box-geolocate {
        width: auto;
        height: auto;
        background: #040a2b url(/media/export/images/click_collect/map_geolocate.jpg) no-repeat center center;
        position: absolute;
        width: 49px;
        height: 50px;
        right: 0.5em;
        [dir='rtl'] & {
          right: inherit;
          left: 0.5em;
        }
        bottom: 5%;
        z-index: 1;
        overflow: hidden;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 50%;
        color: #000;
        text-align: center;
        text-overflow: ellipsis;
        cursor: pointer;
        padding: 10px;
        .loading span:before {
          content: '';
        }
      }
      .address-map-directions-panel {
        @extend %click-collect-9;
        left: -280px;
        left: 0;
        header {
          @extend %click-collect-10;
          height: 41px;
          float: left;
          border-bottom: 1px solid #ededed;
          h2 {
            font-family: 'Optima Display Roman';
            padding-left: 0.5em;
            float: left;
            margin-right: 4px;
            font-size: 20px;
          }
          .button {
            float: left;
            padding: 0 3px;
            font-size: 10px;
            border: 0;
            line-height: 23px;
          }
          div a.close span:before {
            display: block;
            font-family: icomoon;
            speak: none;
            font-size: 16px;
            color: #fff;
            text-decoration: none;
          }
        }
        .directions {
          float: left;
        }
        a.close {
          @extend %click-collect-11;
          background: #ffffff url(/media/export/images/click_collect/map_close.jpg) no-repeat -6px 0;
          height: 41px;
          padding: 0 14px;
          top: 0;
          right: 11px;
          [dir='rtl'] & {
            right: inherit;
            left: 0;
          }
          display: block;
          position: absolute;
          text-decoration: none;
          z-index: 1;
          border: none;
        }
      }
      .address-map-location-panel {
        @extend %click-collect-9;
        header {
          @extend %click-collect-10;
          width: 288px;
          border-bottom: 1px solid #ededed;
          h2 {
            padding-left: 0.5em;
            font-family: 'Optima Display Roman';
            font-size: 20px;
          }
        }
        &.active {
          left: 0;
          [dir='rtl'] & {
            right: 0;
          }
          a.close span:before {
            content: attr(data-active);
          }
        }
        a.close {
          @extend %click-collect-11;
          background: #040a2b url(/media/export/images/click_collect/map_panel_close.jpg) no-repeat 4px -5px;
          height: 41px;
          width: 30px;
          top: 0;
          right: -5px;
          [dir='rtl'] & {
            @media (min-width: 767px) {
              right: 275px;
              transform: rotate(0deg) scaleX(-1);
            }
          }
          display: block;
          position: absolute;
          text-decoration: none;
          z-index: 1;
          span:before {
            display: block;
            content: attr(data-inactive);
            font-size: 16px;
            text-decoration: none;
          }
        }
      }
      .map {
        height: 100%;
        width: 100%;
      }
      .active a.close {
        background: #ffffff url(/media/export/images/click_collect/map_close.jpg) no-repeat -5px 0;
        right: 11px;
        [dir='rtl'] & {
          right: 275px;
        }
      }
      .location_more_info {
        display: none;
      }
    }
    .search-box-cancel span:before {
      padding-top: 5px;
    }
    .search-box-geolocate span:before {
      font-size: 25px;
      line-height: 25px;
      padding: 2px 0 2.5px 3.5px;
    }
  }
  &.local-collection .overlay-content .map-container .address-map-search-panel .search-box-toggle {
    background: #040a2b url(/media/export/images/checkout/map_stores_toggle.png) no-repeat center center;
    width: 80px;
  }
  &.store-collection .overlay-content .map-container .address-map-search-panel .search-box-toggle {
    background: #040a2b url(/media/export/images/checkout/map_ups_toggle.png) no-repeat center center;
    width: 80px;
  }
}

#map-foreground-node {
  padding: 0;
}

.adp {
  margin-top: 10px;
}

.adp-placemark {
  background-color: #fff;
  td {
    padding: 2px 0 2px 15px;
    color: #000;
  }
  .adp-text {
    font-size: 11px;
    font: 12px/14px 'AkzidenzGrotesk W1G';
  }
}

.adp-substep {
  color: #000;
  @extend %click-collect-13;
}

.adp-summary {
  color: #000;
  font-size: 11px;
  font: 12px/14px 'AkzidenzGrotesk W1G';
}

.adp-text {
  color: #000;
}

@media (max-width: 1023px) {
  .map-container .address-map-search-panel .search-box-field {
    width: 40%;
    font-size: inherit;
  }
}

@media (max-width: 850px) {
  .click_and_collect_map .overlay-content .map-container .address-map-search-panel {
    .search-box-field {
      width: 100%;
      margin: 0;
      float: none;
      clear: both;
    }
    .search-box-submit {
      margin-top: 1em;
      width: 32%;
      margin-left: 0;
    }
    .search-box-toggle {
      margin-top: 1em;
      width: 32%;
      margin-left: 0;
    }
    .search-box-geolocate {
      margin-top: 1em;
      width: 32%;
    }
    .search-box-cancel {
      margin-top: 1em;
      width: 32%;
    }
  }
}

@media (max-width: 767px) {
  .map-container {
    .address-map-search-panel .search-box-field {
      width: 30%;
    }
    .map-info-window .location {
      width: 100%;
      padding: 0;
      .title {
        padding-bottom: 0;
      }
    }
    .location .title span {
      line-height: 1.1em;
      font-weight: bold;
    }
    .address-map-location-panel .location {
      .distance {
        display: none;
      }
      .title .distance {
        display: block;
        .uom {
          color: #888888;
        }
      }
    }
  }
  #map-foreground-node {
    z-index: 1000000;
  }
  .click_and_collect_map .overlay-content {
    .map-container {
      .address-map-search-panel {
        width: 300px;
        .search-box-submit {
          float: left;
          [dir='rtl'] & {
            float: right;
            margin-right: 2px;
          }
          padding: 0 !important;
          width: 10%;
          margin: 0;
        }
        .status-bar-wrapper {
          margin: 20px;
          position: fixed;
          bottom: 50px;
          width: 100%;
          margin: 0;
          left: 0;
          text-align: center;
        }
        .search-box-field {
          font-size: 12px !important;
          letter-spacing: normal;
          width: 89%;
          float: left;
          height: 30px;
        }
      }
      .map-info-window {
        max-width: 150px;
        .location .image {
          display: none;
        }
      }
      .address-map-directions-panel {
        width: 100%;
        top: 0;
        max-height: 100%;
        overflow-y: scroll;
        background-color: #fff;
        padding-right: 0;
        header {
          width: 100%;
          border-bottom: 1px solid #ededed;
          h2 {
            font-family: 'Optima Display Roman';
            font-size: 20px;
          }
        }
        a.close {
          right: 0px;
          width: 30px;
          height: 30px;
          top: 3px !important;
          background: transparent url(/media/export/images/click_collect/map_close.png) no-repeat center center;
          text-indent: 100%;
          border: none;
        }
      }
      .address-map-location-panel {
        width: 100%;
        top: auto;
        left: 0;
        [dir='rtl'] & {
          right: 0;
        }
        bottom: 0;
        width: 100%;
        height: 30px;
        margin: 0;
        padding-bottom: 0;
        overflow: hidden;
        header {
          width: 100%;
          padding-top: 5px;
        }
        &.active {
          top: 0;
          height: auto;
        }
        a.close {
          right: 0;
          [dir='rtl'] & {
            right: unset;
            left: 0;
          }
          background: #fff url(/media/export/images/click_collect/map_mobile_panel_close.png) no-repeat center center;
          border: 0;
          height: 30px;
          text-indent: -999em;
          span:before {
            content: '\f0d8';
          }
        }
      }
      .search-box-geolocate {
        bottom: 20%;
      }
      .active a.close {
        height: 30px;
        width: 30px;
        top: 3px !important;
        background: transparent url(/media/export/images/click_collect/map_close.png) no-repeat center center;
        text-indent: 100%;
      }
    }
    .search-box-cancel span:before {
      padding-top: 0;
    }
  }
  #shipmethod-panel .ship-method-group-label {
    input {
      float: right;
      width: 6%;
      margin: 0 !important;
    }
    label {
      float: right;
      width: 94%;
      .overlay-wrapper {
        position: relative;
        left: -15px;
        display: inline;
      }
    }
  }
  body#viewcart .viewcart-buttons-panel .continue-buttons {
    a.continue-checkout {
      margin: 10px 0;
    }
    .paypal-checkout.disabled {
      margin: 0;
    }
  }
  .address-map-location-panel header h2 {
    margin-top: 0;
  }
  .search-box-wrapper .search-box .search-box-submit {
    height: 200px;
  }
  .map-info-window .location div.button {
    height: 50px;
    a {
      width: 100%;
      text-align: center;
      &.select-access-point {
        margin-bottom: 5px;
      }
    }
  }
  .adp-substep:nth-child(3) {
    word-break: break-word;
  }
  .left #checkout_shipmethod .ship-method-group-label {
    input[type='radio'] {
      border-radius: 6px;
    }
    label .overlay-wrapper p {
      letter-spacing: 0.15em;
    }
  }
  .gm-style {
    .opening_hours {
      display: none;
    }
  }
}

@media (max-width: 480px) {
  .click_and_collect_map .overlay-content .map-container {
    .address-map-search-panel {
      width: auto;
      margin: 0;
      border-radius: 0;
      border-width: 0 0 1px 0;
      padding: 0 0 1em;
    }
    .address-map-location-panel {
      top: auto;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 30px;
      margin: 0;
      padding: 0;
      overflow: hidden;
      &.hover {
        left: 0;
      }
      &.active {
        top: 0;
        height: auto;
      }
      header {
        padding-top: 5px;
      }
      a.close {
        span:before {
          content: '\2b06';
        }
      }
      .location:first-child {
        padding-top: 5px;
        margin-top: 5px;
      }
    }
  }
}

@media screen and(min-width: 320px) and(max-width: 767px) {
  .shipmethod-delivery {
    display: block;
    margin-bottom: 5px;
  }
  #shipmethod-panel .ship-method-group-label {
    width: 100%;
    float: left;
    input {
      margin-left: 0;
      height: 13px;
    }
    input[type='radio'] {
    }
    label a.more-info {
      float: right;
    }
  }
  #viewcart #shipmethod-panel header {
    text-align: left;
  }
  div.column.left #shipmethod-panel div.content {
    text-align: left;
  }
  .left #checkout_shipmethod .ship-method-group-label {
    input[type='radio'] {
      margin: 0 0 15px 0 !important;
    }
    label {
      margin: 0 0 15px 0 !important;
    }
  }
  .viewcart-buttons-panel .continue-buttons .paypal-checkout {
    width: 91%;
    height: 32px;
    &.disabled {
      width: 100%;
      background: url(/media/export/images/checkout/pay-pal-grey.jpg) top right no-repeat;
      height: 32px;
      span.paypal-message {
        text-align: right;
      }
    }
  }
  section.panel div.two-column-form div.form-item.address-to-use {
    width: 100%;
  }
  #checkout_shipmethod .ship-method-group-label label {
    text-transform: none;
  }
  .select-address div.shipmethod-delivery label {
    display: inline;
    font-weight: normal !important;
    text-transform: none !important;
    margin-bottom: 0 !important;
  }
  .right #checkout_shipmethod .ship-method-group-label input[type='radio'] {
    margin: 0;
  }
  .local-collection {
    .show-collection-point {
      h5 {
        margin-bottom: 0px;
      }
      a.local-collection-link {
        margin-left: 25px;
      }
    }
  }
}

@media (max-width: 320px) {
  .overlay-container {
    background-color: #fff;
    border: 1px solid #ccc;
    overflow-y: auto;
    overflow-x: auto;
    position: fixed;
    z-index: 201;
    .close-container {
      margin: 5px 0;
      float: right;
    }
    a.close-link {
      background: transparent url(/images/css/lb-close.gif) no-repeat scroll 0 2px;
      color: #000;
      padding-left: 14px;
    }
  }
  .overlay-background {
    background-color: #000;
    filter: alpha(opacity=50);
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    z-index: 200;
  }
  #order-summary-panel {
    .label {
      width: 77%;
    }
    .value {
      width: 23%;
    }
  }
  select.overlay-hidden {
    display: none;
  }
  div.column.right #order-summary-panel div.content {
    padding: 10px 10px 10px 0 !important;
  }
}

@media (max-width: 530px) {
  section.viewcart-buttons-panel .continue-buttons a {
    margin-bottom: 0 !important;
  }
  .click_and_collect_map .overlay-content .map-container .address-map-search-panel {
    width: 290px;
    top: 50px;
    .search-box-field {
      font-size: 12px;
      width: 80%;
      float: left;
      height: 23px;
    }
    .search-box-submit {
      margin: 1px 0 0;
      width: auto;
      padding: 0;
      height: 26px;
      width: 26px;
    }
  }
}

@media screen and(min-width: 321px) and(max-width: 360px) {
  right #checkout_shipmethod .ship-method-group-label label {
    width: 87%;
  }
}

@media screen and(min-width: 361px) and(max-width: 768px) {
  #index {
    .ship-method-group-label {
      width: 100%;
    }
    #order-summary-panel {
      margin: 0;
    }
  }
  .right #checkout_shipmethod .ship-method-group-label label {
    width: 92%;
  }
}

@media screen and(min-width: 768px) and(max-width: 1024px) {
  .click_and_collect_map .overlay-content .map-container .address-map-search-panel {
    width: 40%;
    .search-box-field {
      width: 84%;
      margin-top: 18px;
      margin-left: 10px;
      border-radius: 0;
      float: left;
    }
    .search-box-submit {
      float: left;
      width: 30px;
      margin-top: 20px;
      margin-left: 5px;
    }
  }
  .overlay-content {
    .status-bar-wrapper {
      .status-bar {
        .message {
          font-family: 'AkzidenzGrotesk W1G';
          font-size: 17px;
          &.information {
            .message-text {
              background-color: #040a2b;
              color: #fff;
            }
          }
          &.error {
            .message-text {
              background-color: #fff;
              color: red;
            }
          }
          &.success {
            .message-text {
              background-color: #ccc;
              color: #040a2b;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .sign-in-panel.finished {
    display: block !important;
  }
  .checkout {
    #shipmethod-panel {
      #checkout_shipmethod {
        .form-item {
          position: static;
        }
      }
    }
  }
  .click_and_collect_map {
    .overlay-content {
      .map-container {
        .map-info-window {
          .location {
            .location_description {
              display: none;
            }
            .location_more_info {
              color: #000 !important;
              text-decoration: underline;
              cursor: pointer;
              line-height: 2em !important;
              display: block;
            }
          }
        }
      }
    }
  }
}

.adp-step {
  @extend %click-collect-13;
}

#adp-placemark {
  font-size: 11px;
  font-family: Arial, sans-serif;
}

.address-map-location-panel .button {
  overflow: hidden;
  height: 20px;
  line-height: 18px;
  a {
    background: #040a2b;
    color: #fff;
    cursor: pointer;
    font-family: inherit;
    font-size: 11px;
    font-weight: normal;
    margin-bottom: 3px;
    padding: 1px 0px 0;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 0;
    margin-right: 6px;
    float: left;
    [dir='rtl'] & {
      float: right;
    }
    border: 0;
    text-align: center;
    width: 46%;
  }
}

html.mac .click_and_collect_map .overlay-content .map-container {
  .address-map-directions-panel header {
    height: 35px !important;
  }
  .address-map-location-panel header {
    height: 35px !important;
  }
}

.directions .adp-summary span {
  &:nth-child(1) {
    display: block;
    text-align: right;
    padding-right: 10px;
  }
  &:nth-child(2) {
    display: none;
  }
  &:nth-child(3) {
    padding-left: 40px;
    text-transform: capitalize;
  }
}

table.adp-directions tr td .adp-distance {
  padding-right: 10px;
}

.left {
  #checkout_shipmethod {
    input[type='radio'] {
      #{$ie8} & {
        float: none !important;
      }
    }
  }
}

.right {
  #checkout_shipmethod {
    .radio-wrapper {
      #{$ie8} & {
        display: block;
      }
    }
  }
}

.page-utilities__cart-count {
  @if $gluxre_update == false {
    min-width: 5px !important;
  }
}

.local-collection {
  .show-collection-point {
    h5 {
      margin-bottom: 0px;
    }
    a.local-collection-link {
      margin-#{$ldirection}: 35px;
    }
  }
}

.overlay-content {
  .status-bar-wrapper {
    .status-bar {
      .message {
        padding: 0px !important;
        font-family: 'AkzidenzGrotesk W1G';
        font-size: 17px;
        &.information {
          .message-text {
            padding: 10px;
            background-color: #040a2b;
            color: #fff;
          }
        }
        &.error {
          .message-text {
            padding: 10px;
            background-color: #fff;
            color: red;
          }
        }
        &.success {
          .message-text {
            padding: 10px;
            background-color: #ededed;
            color: #040a2b;
          }
        }
      }
    }
  }
}
