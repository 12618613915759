.enhanced_delivery_page {
  &#shipping,
  &#payment {
    .page-wrapper {
      .page-header {
        padding: 0;
        .header-inner {
          .header-items {
            background: $color-white;
            box-shadow: 0 2px 10px $color-black-transparent;
            height: 80px;
            padding: 0;
          }
        }
      }
    }
  }
}
