/* Checkout page styling */
.checkout {
  .social-login {
    &__divider {
      width: 100%;
      &:before {
        background: $color-light-gray;
        height: 1px;
      }
    }
    &__email-opt-in {
      display: block;
      text-align: $ldirection;
    }
    &__terms {
      text-align: $ldirection;
      @media #{$large-up} {
        padding: 0;
      }
    }
  }
}

.page-wrapper-checkout {
  .checkout {
    overflow: unset;
  }
}
/* Account signin page styling */
.sign-in-page {
  .divider {
    display: none;
  }
  .social-login {
    &__divider {
      width: 100%;
    }
    &__email-opt-in {
      width: 100%;
      text-align: $ldirection;
      .email-optin {
        text-transform: none;
        letter-spacing: 0.05em;
        font-size: 12px;
      }
    }
    &__terms {
      text-align: $ldirection;
      padding: 0;
      p {
        font-size: 12px;
      }
    }
  }
}

.social-login {
  &__terms {
    display: block;
  }
  .tooltip {
    margin-top: 5px;
  }
}
/* Account Profile Page */

.device-pc {
  .social-info {
    padding-top: 0;
    border-top: none;
    padding-#{$ldirection}: 19px;
    @media #{$medium-up} {
      margin-#{$ldirection}: 25%;
    }
    @media #{$xlarge-up} {
      margin-#{$ldirection}: 20%;
    }
    &__header {
      font-size: 20px;
      padding-top: 20px;
      @media #{$medium-up} {
        font-size: 30px;
      }
    }
  }
  .pg_wrapper.account-page.sidebar-page {
    margin-top: 0;
  }
}

.device-mobile {
  .social-info {
    border-top: 0;
    padding: 0 25px 18px;
    &__header {
      font-size: 20px;
    }
  }
  .social-login {
    &__container {
      padding: 27px;
    }
  }
  .page-wrapper-checkout {
    .social-login {
      &__container {
        padding: 0;
      }
    }
  }
}

.account-page {
  .sidebar-page {
    &__content {
      padding: 0;
    }
  }
}

#registration {
  .page-wrapper {
    .social-info {
      &__header {
        padding-bottom: 16px;
        border-bottom: solid 1px $color-light-gray;
        margin-bottom: 20px;
      }
      &__content p {
        a {
          text-decoration: underline;
        }
      }
      &__connect {
        display: inline-block;
        margin-bottom: 0;
        .fb-overlay-container {
          display: inline-block;
        }
      }
    }
  }
}
/* GNAV styling */
#cboxLoadedContent {
  .social-login.gnav {
    margin-#{$rdirection}: 0;
    @media #{$medium-up} {
      @include swap_direction(margin, 0 100px);
    }
    .social-login {
      &__container {
        text-align: center;
        padding: 27px;
      }
      &__divider {
        width: 100%;
      }
      &__email-opt-in {
        text-align: $ldirection;
        label {
          text-transform: capitalize;
          letter-spacing: 0.05em;
        }
      }
      &__terms {
        text-align: $ldirection;
        letter-spacing: 0.05em;
        font-size: 12px;
      }
    }
  }
}
/* Order confirmation page */
#confirmation-page {
  .social-login {
    @media #{$medium-up} {
      padding: 0;
    }
    &__container {
      text-align: $ldirection;
      padding-bottom: 0;
      margin-bottom: 20px;
    }
    &__terms,
    &__divider {
      display: none;
    }
    &__title {
      display: block;
      font-size: 40px;
      font-family: $optimalight;
      text-transform: capitalize;
      letter-spacing: 0;
      @media #{$medium-up} {
        font-size: 50px;
      }
    }
    &__info {
      margin-bottom: 10px;
      display: block;
    }
    &__email-opt-in {
      margin-#{$ldirection}: 0;
      label {
        text-transform: none;
        letter-spacing: 0.05em;
        padding-top: 0;
      }
    }
    .fb-overlay-container {
      width: auto;
    }
  }
}
/* Profile preferences page */
.account-profile {
  &__connected-accounts {
    text-transform: uppercase;
    font-size: 12px;
  }
}
/* Bokk Appointment */
.appointments-container {
  .sign-in-form-container {
    .social-login {
      &__divider {
        width: 100%;
      }
      &__email-opt-in {
        padding-top: 20px;
        text-align: $ldirection;
        a {
          text-transform: none;
          letter-spacing: 0.1em;
          font-size: 12px;
        }
      }
      &__terms {
        text-align: $ldirection;
        padding: 0;
        margin-top: 0;
        p {
          font-size: 12px;
        }
      }
    }
  }
}

#registration,
.confirmation-panel {
  .social-login,
  .social-info {
    label {
      font-size: 15px;
    }
  }
}
