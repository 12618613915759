.product__inventory-status-container {
  margin-top: 10px;
  .cta {
    margin-#{$ldirection}: 10px;
  }
}

.mpp-compare-vba {
  display: none;
}

.product__price-per-unit {
  font-size: 13px;
  margin-top: 5px;
}

.product-full__price-text {
  float: none;
  font-size: 15px;
}

.spp-product__legal {
  margin-top: 5px;
}

select.product-full__quantity,
a.selectBox.product-full__quantity {
  width: 115px;
}

.spp,
.node-spp-related-content-item-set {
  .product-full__description {
    .product-full__description-section {
      .BVRROverallRatingContainer {
        width: 100%;
      }
    }
    ul.shade-list {
      margin: 16px 0 0 0;
    }
  }
}

.mpp-compare__bag {
  .mpp-compare__bag-container {
    float: $ldirection;
    width: 100%;
    min-height: 0px !important;
    .mpp-compare__swatch-select-container {
      float: $ldirection;
      width: 100%;
    }
    .mpp-compare__swatch-list-container {
      float: $ldirection;
      .mpp-compare__swatch-list {
        min-height: 150px;
      }
    }
    .mpp-compare__shadename {
      float: $ldirection;
      width: 100%;
    }
    .product__inventory-status {
      float: $ldirection;
      width: 100%;
    }
    .compare_add_to_bag {
      float: $ldirection;
      position: relative !important;
    }
  }
}

.special-offers-page {
  .hero-tout {
    .hero-tout-container {
      .tout__product {
        #{$ldirection}: 95.1% !important;
      }
    }
    .tout-headline {
      h1 {
        span {
          font-size: 70px !important;
        }
      }
    }
  }
}

.signup-tout-wrapper {
  .footer-social-links {
    .facebook {
      background-position: 0 -3395px !important;
    }
    .pinterest {
      background-position: 0 -1243px !important;
    }
    .instagram {
      background-position: 0 -1150px !important;
    }
    .twitter {
      background-position: 0 -1331px !important;
    }
  }
}
