.enhanced_delivery_page {
  .pg_wrapper {
    .checkout__content {
      .loyalty-panel {
        border-bottom: 1px solid $color-navy;
        border-top: 1px solid $color-navy;
        margin-bottom: 16px;
        max-width: 562px;
        padding: 13px 15px;
        .loyalty-points__description {
          &.form-item {
            margin-bottom: 0;
          }
        }
        #create_loyalty_account_container {
          margin-top: 16px;
          .form-item {
            .field {
              height: 54px;
            }
            label {
              font-size: 12px;
              letter-spacing: 1.25px;
              padding-#{$ldirection}: 30px;
              text-transform: capitalize;
            }
            input[type='checkbox'] ~ label {
              &::before,
              &::after {
                border: solid 1px $color-grey-lighter;
                content: '';
                position: absolute;
              }
              &::before {
                #{$ldirection}: 0;
                border-radius: 3px;
                color: $color-grey-lighter;
                height: 18px;
                width: 18px;
              }
              &::after {
                #{$ldirection}: 6px;
                background: none;
                border-width: 0 2px 2px 0;
                display: block;
                height: 11px;
                top: 5px;
                transform: rotate(45deg);
                width: 5px;
              }
            }
          }
          .terms-icon-container {
            margin-top: 0;
          }
          .info-link-gdpr {
            @include swap_direction(margin, 22px 0 0 30px);
            &::before {
              #{$ldirection}: 15px;
            }
          }
        }
        .checkout__panel-title {
          border-bottom: 0;
          color: $color-navy;
          cursor: pointer;
          font-family: $optimatext;
          font-size: 16px;
          font-weight: normal;
          letter-spacing: 0.25px;
          line-height: 1.25;
          margin-bottom: 0;
          padding-bottom: 0;
          text-transform: uppercase;
          .title-icon {
            float: $rdirection;
            height: 16px;
            line-height: 0.3;
            margin-top: 4px;
            width: 16px;
            &::before,
            &::after {
              background: $color-navy;
              content: '';
              display: block;
              position: relative;
              transform: translate(0);
            }
            &::before {
              height: 2px;
              top: 5px;
              width: 16px;
            }
            &::after {
              height: 16px;
              top: 4px;
              transform: translate(7px, -50%);
              width: 2px;
            }
          }
          &.open {
            .title-icon {
              &::after {
                visibility: hidden;
              }
            }
          }
        }
      }
    }
  }
}
