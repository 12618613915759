///
/// @file layout/_page_header.scss
///
/// \brief The wrapper and styles for the overall page header
///
/// Styleguide pg.21. Padding-top on .page-wrapper and .page-header have an
/// important interplay
///

///
/// Overall wrapper
/// .page-header sits within padding here,  since .page-header sits both abs and
///  fixed. Non-padding styles in layout/_page.scss
///
.page-wrapper {
  padding-top: $header-with-searchbar-item-height;
  @media #{$medium-up} {
    padding-top: 113px;
  }
  @media #{$xlarge-up-tall} {
    #{$landing} & {
      padding-top: $header-full-height;
    }
  }
  #{$min-nav} &,
  .elc-gnav-minimal & {
    padding-top: $header-item-height;
  }
  .site-banner__is-hidden & {
    padding-top: $header-item-height;
    @media #{$medium-up} {
      padding-top: 85px;
    }
  }
}

// Move content down below mobile searchbar when forced mobile view on PC Tablet
.node-type-page.device-mobile {
  .page-wrapper {
    @media #{$medium-only} {
      padding-top: $header-with-searchbar-item-height + $header-vertical-padding;
    }
    @media #{$large-only} {
      padding-top: $header-with-searchbar-item-height + $header-vertical-padding;
    }
  }
}

.page-iframe {
  .page-wrapper {
    padding-top: 0 !important;
  }
}

///
/// Specific Header element
///
.page-header {
  background: $color-white;
  width: 100%;
  $page-header--position: absolute;
  position: $page-header--position;
  #{$ldirection}: 0;
  top: 0;
  z-index: 100;
  &.is-alternate {
    z-index: 999999;
  }
  // min-height: $header-collapsed-height; // don't really need this

  // Right after mobile
  @media #{$medium-up} {
    background: rgba(255, 255, 255, 0.65);
    padding: 0;
    min-height: $header-collapsed-height;
    #{$ie8} & {
      min-height: $header-collapsed-height-ie8;
    }
  }

  // Front is bigger
  #{$landing} &:not(#{$sticky}) {
    // When short and wider than ipad landscape (reduced nav)
    @media #{$xlarge-up-tall} {
      min-height: $header-full-height;
    }
  }

  // Alternate (open, dark) state. The alternate state tag is actually ".something"
  // so we don't include the .
  &#{$alt} {
    @include background($color-navy);
    position: fixed;
  }
  &#{$dark-nav} {
    @media #{$medium-up} {
      @include background($color-navy);
      color: $color-white;
    }
  }

  // Sticky state. See main-pc.js for sticky behavior
  &#{$sticky} {
    @include animation-name(fadeHeaderInDownOnMobile);
    position: fixed;
    @media #{$medium-up} {
      @include animation-name(fadeHeaderInDown);
      background-color: $color-white;
    }
  }

  &#{$search} {
    @media #{$small-only} {
      &#{$sticky} {
        @include animation-name(none);
        position: $page-header--position;
      }
    }
  }

  // Animation for the header dropdown when NOT currently opn ("alt")
  &:not(#{$alt}) {
    @include animationDuration(1s); // base/_animations.scss
    #{$renutriv} & {
      background: url($base-theme-image-path + 'rgbapng/000000ff-10.png');
      background: black;
      @media #{$medium-up} {
        background: url($base-theme-image-path + 'rgbapng/000000a6-10.png');
        background: rgba(0, 0, 0, 0.65);
      }
    }
  }

  // Hide for checkout, mobile checkout comps style
  #{$min-nav} &,
  .elc-gnav-minimal & {
    min-height: 50px;
    @media #{$small-only} {
      border-bottom: 1px solid $color-light-gray;
    }
    // back button, hide on medium up
    @media #{$medium-up} {
      .back-button__left-caret-icon {
        display: none;
      }
    }
    .back-button__left-caret-icon {
      background-size: auto auto;
      background-position: 0 -8820px;
      width: 30px;
      height: 40px;
      position: absolute;
      top: 5px;
    }
  }
  @media #{$small-only} {
    border-bottom: 1px solid $color-light-gray;
  }
} //.page-header
#{$alt-color-white} {
  &.page-header__dim-overlay {
    @media #{$small-only} {
      opacity: 0.65;
      background: $color-white;
      height: 100%;
      #{$ldirection}: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 99;
    }
  }
}

.header-inner {
  width: 100%;
  position: relative; // close buttons need this
}

.header-items {
  @media #{$small-only} {
    position: relative;
  }
  #{$alt} & {
    background-color: $color-white;
  }
}

.site-header__banner {
  .site-banner {
    background-color: $color-navy;
    @include swap_direction(padding, 5px 0);
  }
  .site_banner_slider {
    color: $color-white;
    text-align: center;
    position: relative;
    @include swap_direction(margin, 0 auto);
    overflow: hidden;
    p {
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 0 25px);
      line-height: 20px;
      letter-spacing: 0;
      width: 100%;
    }
    a {
      color: $color-periwinkle-blue;
      text-decoration: underline;
      cursor: pointer;
    }
    ul.slides {
      li {
        display: -webkit-box !important;
        -webkit-box-align: center;
        -webkit-justify-content: center;
        .custom {
          width: 100%;
        }
      }
    }
  }
  .site-banner__close {
    .site-banner__close-icon {
      background-size: auto auto;
      background: url('/media/images/global/sprites-s38a9885fce.png') 0 -8476px no-repeat;
      width: 13px;
      height: 12px;
      position: absolute;
      #{$rdirection}: 4px;
      top: 6px;
      cursor: pointer;
    }
  }
}
