.beauty-questions__list {
  @media #{$medium-up} {
    li:first-child {
      padding-#{$ldirection}: 4px;
    }
  }
  @media #{$medium-down} {
    li {
      clear: both;
    }
  }
}
