.product {
  .samples-page & {
    @media #{$medium-up} {
      height: 330px;
      margin-bottom: 20px;
      .sample-sku-list {
        position: absolute;
        bottom: 10px;
        #{$ldirection}: 30px;
        #{$rdirection}: 30px;
      }
    }
  }
}

.samples-page {
  #samples-panel {
    .samples-top {
      float: $ldirection;
      width: 100%;
    }
    form#checkout_samples {
      p {
        @include swap_direction(margin, 20px 0);
        float: $ldirection;
        text-align: center;
        width: 100%;
      }
      .samples-buttons.top {
        float: $ldirection;
        width: 100%;
      }
      .sample-products {
        float: $ldirection;
        width: 100%;
      }
      .samples-buttons.bottom {
        float: $ldirection;
        width: 100%;
      }
    }
  }
  .product:after {
    @media #{$medium-down} {
      clear: both;
      content: '';
      display: table;
    }
  }
}

// GWP MYOG styles start here
//colors
$color_star_dust_approx: #9e9ea0;
$color_celeste_approx: #cccccc;

.wp-sample-page-pc {
  .wp_messages {
    font-size: 16px;
    text-align: center;
    padding: 2% 10%;
  }
  .no-offer-msg {
    margin-top: 10%;
  }
  .product {
    height: auto !important;
  }
  .column-1 {
    width: 99% !important;
    .prod-desc {
      padding-bottom: 5%;
    }
    .sku-details {
      padding-left: 33%;
    }
  }
  .column-2 {
    width: 49% !important;
    .prod-desc {
      padding-bottom: 10%;
    }
    .sku-details {
      padding-left: 15%;
    }
  }
  .column-3 {
    width: 33% !important;
    .prod-desc {
      padding-bottom: 15%;
    }
  }
  .skus {
    position: relative;
    margin-left: 0;
  }
  .wp-label-wrap {
    padding-top: 2%;
  }
  .wp-radio-label {
    padding-top: 0 !important;
  }
  .myog-btn-holder {
    float: right;
    padding-top: 10%;
  }
  .wp-footer-top {
    margin-bottom: 20px;
    text-align: left;
  }
  .wp-footer-bottom {
    margin-bottom: 20px;
    text-align: left;
  }
  .checkbox-disable {
    color: $color_star_dust_approx;
    cursor: default;
  }
  .button-disable {
    background-color: $color_celeste_approx;
    cursor: default;
  }
  .clear-both {
    clear: both;
  }
  .float-none {
    float: none;
  }
  .product-img img {
    width: auto !important;
  }
}

.wp-sample-page-mb {
  .banner-img img {
    width: 100%;
  }
  .prod-items {
    width: 74%;
    float: left;
  }
  .border-bottom {
    border-bottom: 1px solid #929292;
    margin-bottom: 8%;
  }
  .no-top_border {
    border-top: none;
  }
  .product {
    height: auto;
  }
  .prod-desc {
    margin-top: 5%;
  }
  .wp-samples-contrl {
    margin: 10% 0;
  }
  .wp-lable-mb {
    margin: 3% 0;
  }
  .checkbox-disable {
    color: $color_star_dust_approx;
    cursor: default;
  }
  .button-disable {
    background-color: $color_celeste_approx;
    cursor: default;
  }
  .no-offer-msg {
    margin-top: 20%;
    text-align: center;
  }
  .wp-footer-top {
    margin-bottom: 20px;
  }
  .wp-footer-bottom {
    margin-bottom: 20px;
  }
}

.viewcart {
  .wp_message {
    padding-top: 4%;
    font-size: 16px;
    a {
      text-decoration: underline;
      font-weight: bold;
    }
  }
  .wp-name {
    padding-bottom: 3%;
    line-height: 1;
  }
  .gift-item {
    font-size: 10px;
    cursor: pointer;
  }
  .loading-img {
    position: absolute;
    width: auto !important;
    padding: 0 0 0 10%;
  }
}

// GWP MYOG styles ends here
