.blue_button {
  color: white;
  background-color: $extended_mpp_black_pearl;
}

.page-utilities-loyalty-elc-nodeblock {
  float: left;
  .page-utilities__account {
    margin-top: 0px;
  }
}

.registration-page {
  &__personal-info {
    .form-item {
      margin: 20px 0 0 0;
      &.first-name,
      &.csrHide {
        clear: both;
      }
    }
  }
}

.page-wrapper {
  .checkout {
    .checkout__content {
      width: 65% !important;
    }
    .checkout__sidebar {
      width: 35% !important;
      .offer-code-panel {
        .offer-code__form {
          .offer_submit {
            @include swap_direction(padding, 0px);
          }
        }
      }
    }
    padding-bottom: 150px;
  }
}

#viewcart {
  .recommended-products-panel {
    .recommended-product-items {
      .sku-brief-checkout__controls {
        input[type='submit'] {
          max-width: 100%;
          margin: 0;
        }
      }
    }
  }
}

.empty.viewcart {
  .signin-to-see-cart {
    font-size: 15px;
  }
  .messages {
    text-align: center;
    font-family: 'Optima Display Roman';
    font-size: 22px;
    ul.error {
      color: #9eafff;
    }
  }
  .top-viewcart-buttons {
    .go-shopping {
      @extend .blue_button;
    }
  }
}

#address_form_container {
  #address,
  #address_billing {
    .address-overlay-shipping {
      .default_ship_container {
        padding-#{$ldirection}: 0;
      }
    }
    a.form-cancel {
      display: none;
    }
  }
}

.page-wrapper-checkout {
  .recommended-products-panel {
    .recommended-products__list > li > .sku-brief-editorial {
      height: 433px;
    }
  }
  .viewcart-buttons {
    .continue-checkout {
      @extend .blue_button;
    }
    .continue-checkout.disabled {
      background-color: $color-light-gray;
      border: 1px solid;
    }
  }
  .samples-panel {
    form#checkout_samples {
      p.available {
        font-family: 'OptimaDisplayLight';
        font-size: 50px;
        line-height: 50px;
        margin-bottom: 11px;
        text-transform: lowercase;
      }
    }
    .samples-buttons {
      .return-link {
        font-size: 15px;
      }
    }
    .disclaimer-msg {
      font-size: 15px;
      margin-top: 70px;
      text-align: left;
      width: 75%;
      padding: 0 24px;
    }
  }
  .samples-page.extended-mpp {
    .samples {
      .product-img {
        width: 100%;
        img {
          height: 100%;
        }
      }
      li.sample-cell {
        height: 600px !important;
        .product-name {
          font-size: 22px;
        }
        .details {
          text-align: center;
          width: 100%;
          float: left;
          position: relative;
          min-height: 140px;
          .form_element {
            position: absolute;
            bottom: 0px;
            width: 100%;
            label {
              width: 100%;
              span {
                display: block;
                width: 100%;
              }
            }
          }
        }
        .sample-select-button.selected {
          @extend .blue_button;
        }
      }
    }
  }
}

.checkout.sign-in-page.no-sidebar {
  h2.checkout__panel-title {
    font-size: 50px;
    padding: 0 0 20px 0px;
  }
  .form-item {
    input[type='text'],
    input[type='password'] {
      max-width: inherit;
    }
  }
  .checkout__sidebar {
    display: none;
  }
  .column.content {
    display: block !important;
    .return-user-link,
    .continue-guest-link {
      display: none;
    }
  }
}

#updated_terms_notification {
  div {
    &.bt-bottom-right {
      position: fixed;
      right: 10px;
      bottom: 256px;
      width: 21.5em;
      border: 1px solid #040a2b;
      @include swap_direction(padding, 10px);
      background-color: #040a2b;
      z-index: 1000;
      font-size: 12px;
      line-height: 16px;
      a {
        color: #040a2b;
        font-weight: bold;
      }
      a.bt-close-link {
        display: block;
        width: 15px;
        height: 15px;
        background: url(/media/images/cookies/close_x.png) 50% 50% no-repeat;
        text-indent: -9999em;
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }
    .bt-inner-content {
      margin: 0;
      background-color: white;
      @include swap_direction(padding, 15px 10px 10px);
      p.bt_contentarea {
        font-size: 12px;
      }
    }
  }
}

.section-store-locator.device-pc {
  .store-locator {
    .local-search-form {
      .local-search-form__city {
        float: left;
        width: 195px;
      }
    }
  }
}

.shipping {
  .ship-method-group-label {
    .delivery_option {
      margin: 0px 0px 18px 0;
      display: inline-block;
    }
  }
  ul.address_controls {
    margin: 18px 0px;
    li#choose-address {
      margin: 18px 0;
    }
  }
  #address-section-container {
    .sub-block {
      margin: 18px 0px;
    }
  }
}

#cboxLoadedContent {
  #address_form_container {
    .account-page.pg_wrapper {
      margin: 0;
      .required-note.required-message {
        margin: 0 0 18px 0;
      }
      .form_element.title_container.radio {
        margin: 0 0 18px 0;
      }
      .form-item.country_container {
        padding: 0 0 0 5px;
      }
    }
  }
}

#shipping {
  .address-form {
    .form-item.first_name_container {
      padding-right: 0px;
    }
    .form-item.last_name_container {
      padding-left: 20px;
    }
    .form-item.country_container {
      padding-left: 0px;
    }
    .form-item.address-form__phone-label-container {
      font-size: 15px;
      letter-spacing: 0.75px;
    }
  }
  .page-wrapper-checkout {
    .checkout {
      .form-item {
        width: 50%;
        &.full {
          width: 100%;
        }
      }
      #checkout_shipmethod {
        #delivery-options {
          display: block !important;
        }
      }
    }
  }
  .submit {
    margin: 18px 0px;
  }
}

.search-wrapper__banner {
  display: none !important;
}

#payment_select {
  .payment-panel {
    #review-address-info {
      .transaction-item {
        width: 100%;
        padding-#{$ldirection}: 0px;
        position: relative;
        border-bottom: 1px solid #cccccc;
        padding: 18px 0 24px;
        .address-book__controls-link {
          width: 135px;
          border: 1px solid #040a2b;
          line-height: 40px;
          height: 40px;
          right: 12px;
          position: absolute;
          top: 6px;
          bottom: initial;
          margin: 12px 0;
          padding: 0;
          z-index: 1;
          text-decoration: none;
          font-size: 12px;
          text-align: center;
        }
        dl {
          margin-#{$ldirection}: 0;
          dd {
            font-size: 15px;
          }
        }
      }
    }
  }
  .checkout-buttons-container {
    #continue-checkout-btm {
      text-align: #{$rdirection};
    }
  }
  #review-instructions {
    margin-bottom: 25px;
  }
  #checkout-sidebar {
    h3.order-summary-panel__title {
      padding: 0px;
    }
  }
}

#confirmation-page {
  .sub_form {
    margin: 10px 0;
    div {
      margin: 3px 0 7px 0;
    }
    .form_element.password1_container {
      width: 50%;
    }
  }
  .form-submit {
    margin: 10px 0;
  }
}

.search-page {
  .multi-use-style-b-estee-stories-dream-on-elc-nodeblock {
    display: none;
  }
  .homepage-olapic-carousel-elc-nodeblock {
    display: none;
  }
  #search-wrapper {
    .content-results {
      display: none !important;
    }
  }
}

.spp {
  &.product-full {
    .product-full__description {
      .product-replenishment-select {
        display: none !important;
      }
    }
  }
}

#bt_notification {
  div.bt-content {
    padding: 15px 10px 10px !important;
    width: 21.5em;
    border-color: #040a2b !important;
    .bt_contentarea {
      font-size: 12px !important;
    }
  }
}

.tiny-waitlist-overlay {
  .waitlist {
    &_header {
      font-size: 28px;
    }
    &_message {
      line-height: 18px;
    }
  }
  .email_input {
    input {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .email_promo {
    @include swap_direction(margin, 10px 0);
  }
  .align-r {
    text-align: center;
  }
}

.product {
  &__inventory-status-container {
    .product__inventory-status {
      display: inline-block;
      float: none;
      margin-left: 0;
      .product__inv-status-item {
        span {
          display: block;
        }
        .cta {
          @include swap_direction(margin, 10px 0 0 0);
        }
      }
    }
  }
}

.ff-results {
  &__matches {
    &-sub-headline {
      display: none;
    }
    .ff-results__regimen-steps {
      display: none;
    }
  }
}

.chat-parallax {
  .chat-parallax__content {
    width: 100%;
  }
}

.special-offers-page {
  .formatter-123__content--2-up {
    .promo-unit {
      #promo_chat {
        text-align: center;
      }
    }
  }
}

.brand-renutriv {
  #updated_terms_notification {
    .terms-content {
      .bt-inner-content,
      .bt_contentarea {
        color: #040a2b;
      }
    }
  }
}

#viewcart {
  .recommended-products-panel {
    .recommended-product-items {
      .sku-brief-checkout__controls {
        input[type='submit'] {
          width: 140px;
          @include swap_direction(padding, 7px 0);
        }
      }
    }
  }
}

#colorbox {
  &.colorbox__quickshop {
    .quickshop {
      .quickshop__description {
        .quickshop__reviews {
          display: none;
        }
      }
    }
  }
}

@media only screen and (min-width: 769px) {
  .product-full__description {
    margin-left: 0;
  }
}

.product_brief__panel {
  .product_brief__buttons--shaded {
    .product_brief__button-panel,
    .product_brief__button--add-to-bag {
      display: inline-block;
    }
  }
}

.address-delete__link {
  text-decoration: underline;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 700;
}
/* GDPR Live Chat Style */
.page-node-187408,
.page-node-194348 {
  .page-header,
  .page-footer {
    display: none !important;
  }
}

.viewcart {
  .payments-icon {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    margin: 10px auto 0;
    .logo {
      background-position: center;
      margin: 0 7px;
      max-width: 40px;
      width: 100%;
      height: 27px;
    }
  }
}
