.account-favorites__list > li,
.account-replenish__list > li,
.account-purchases__list > li {
  min-width: 210px;
  @media #{$medium-up} {
    float: $ldirection;
    @include column-width(2);
    &:nth-child(2n + 1) {
      clear: both;
    }
    @include swap_direction(margin, 0 0 2em);
  }
  @media #{$large-up} {
    @include column-width(3);
    &:nth-child(2n + 1) {
      clear: none;
    }
    &:nth-child(3n + 1) {
      clear: both;
    }
    @include swap_direction(margin, 0 !important);
  }
}

#address {
  .address.fs {
    .radio.title_container {
      .title_option {
        display: inline-block;
        margin-#{$rdirection}: 10px;
        input[type='radio'] {
          width: 15px;
          height: 15px;
          margin-top: 3px;
        }
      }
    }
  }
}

#registration {
  .sign-in-component__birthday-program {
    .select-month {
      width: 90px !important;
    }
  }
}

.product-result {
  .product-result__button {
    .button--dark-secondary {
      width: 100%;
      padding: 0;
    }
  }
}

.user-login-state {
  @media #{$medium-up} {
    min-width: 375px;
    .user-logged-out {
      display: none !important;
    }
    .user-logged-in {
      display: none;
      a {
        margin-#{$ldirection}: 7px;
        color: $color-darker-gray;
        font-weight: normal;
      }
    }
  }
}

.sign-in-page {
  .divider {
    display: none;
  }
}
.device-pc {
  &.elc-user-state-logged-in {
    #colorbox {
      #cboxClose {
        #{$rdirection}: 4px;
        top: 1px;
      }
    }
  }
}
.device-mobile {
  .address-book-page {
    padding-top: 20px;
  }
}
