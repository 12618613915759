.enhanced_delivery_page {
  .page-wrapper {
    .pg_wrapper {
      .checkout_container {
        .checkout__sidebar {
          background: $color-gray-background;
          box-shadow: 0 2px 10px $color-black-transparent-light;
          flex: 0 0 100%;
          margin: 0;
          max-width: 380px;
          padding-top: 0;
          width: 100% !important;
          .payment-panel {
            background: $color-white;
            margin: 0;
            #review-address-info {
              .shipping-panel__title {
                display: none;
              }
              .transaction-item {
                border-top: 1px solid $color-navy;
                margin: 0;
                padding: 14px 13px 13px;
                position: relative;
                width: 100%;
                &.offer-code-message-section {
                  display: none;
                }
                .transaction-content {
                  margin-#{$ldirection}: 0;
                }
                .checkout__subtitle {
                  color: $color-navy;
                  font-family: $optimaregular;
                  font-size: 16px;
                  font-weight: normal;
                  letter-spacing: 0.25px;
                  line-height: 1.25;
                  margin-bottom: 0;
                  width: 100%;
                  b {
                    font-weight: normal;
                  }
                }
                dl {
                  margin-#{$ldirection}: 0;
                  dt {
                    border-bottom: 0;
                    margin-bottom: 0;
                    padding-bottom: 0;
                  }
                  dd {
                    color: $color-grey-nero;
                    font-size: 12px;
                    font-weight: normal;
                    letter-spacing: 1.25px;
                    line-height: 1.7;
                    padding-top: 12px;
                  }
                }
                .address-book__controls-link {
                  #{$rdirection}: 17px;
                  background-image: url('/media/images/checkout/edit_cart_icon.png');
                  background-size: contain;
                  float: $rdirection !important;
                  height: 18px;
                  position: absolute;
                  text-indent: -9999px;
                  top: 47px;
                  width: 18px;
                }
              }
            }
          }
          #checkout-sidebar {
            .shipping-buttons {
              clear: both;
              padding: 24px 16px;
              position: relative;
              text-align: center;
            }
            .transaction-item {
              .checkout__subtitle {
                &.open {
                  .title-icon {
                    &::after {
                      visibility: visible;
                    }
                  }
                }
              }
            }
            .transaction-item,
            #offer_header {
              font-family: $optimaregular;
              .title-icon {
                #{$rdirection}: 0;
                background: none;
                cursor: pointer;
                float: $rdirection;
                height: 20px;
                line-height: 0.3;
                position: absolute;
                top: 7px;
                width: 20px;
                &::before,
                &::after {
                  background: $color-navy;
                  content: '';
                  display: block;
                  position: absolute;
                  transform: translate(-50%, -50%);
                }
                &::before {
                  #{$rdirection}: 2px;
                  height: 2px;
                  top: 2px;
                  width: 14px;
                }
                &::after {
                  #{$rdirection}: 14px;
                  height: 16px;
                  top: 2px;
                  visibility: hidden;
                  width: 2px;
                }
              }
              &.open {
                .title-icon {
                  width: 16px;
                  &::after {
                    visibility: visible;
                  }
                }
              }
            }
            #loyalty-offer-panel #offer_header {
              text-align: $ldirection;
              .title-icon {
                &::after {
                  visibility: visible;
                }
              }
              &.open {
                .title-icon {
                  &::after {
                    visibility: hidden;
                  }
                }
              }
            }
            .order-summary-panel {
              background: $color-white;
              border-bottom: 0;
              border-top: 1px solid $color-grey-light;
              color: $color-navy;
              margin-top: 0;
              padding: 14px 13px 13px;
              #order-status {
                #status-table {
                  padding: 0;
                  .tax-label {
                    color: $color-grey-light;
                    font-size: 8px;
                    letter-spacing: 1.5px;
                    text-transform: uppercase;
                  }
                  .order-summary-divider {
                    margin: 0;
                    width: 100%;
                  }
                  .shipping-container {
                    margin: 11px 0;
                  }
                }
                .shipping-container {
                  @include swap_direction(margin, 8px 0 0 13px);
                  border-bottom: 1px solid $color-light-gray;
                  padding-bottom: 12px;
                  .shipping-msg {
                    background: $color-grey-transparent;
                    border: 1px solid $color-navy;
                    font-size: 12px;
                    font-weight: normal;
                    line-height: 1.67;
                    padding: 13px 16px;
                  }
                }
                .order-summary-panel__title {
                  line-height: 1.25;
                  padding: 0;
                }
                .order-summary-panel__title,
                .checkout__panel-title {
                  color: $color-navy;
                  font-family: $optimaregular;
                  font-size: 16px;
                  font-weight: normal;
                  letter-spacing: 0.25px;
                  margin: 0;
                  position: relative;
                  text-transform: uppercase;
                  .title-icon {
                    #{$rdirection}: 0;
                    cursor: pointer;
                    float: $rdirection;
                    height: 24px;
                    line-height: 0.3;
                    width: 24px;
                    &::before,
                    &::after {
                      background: $color-navy;
                      content: '';
                      display: block;
                      position: absolute;
                      transform: translate(0);
                    }
                    &::before {
                      #{$rdirection}: 9px;
                      height: 2px;
                      top: 8px;
                      width: 15px;
                    }
                    &::after {
                      #{$rdirection}: 22px;
                      height: 15px;
                      top: 9px;
                      transform: translate(7px, -50%);
                      width: 2px;
                    }
                  }
                  &.open {
                    .title-icon {
                      &::after {
                        visibility: hidden;
                      }
                    }
                  }
                }
                .order-summary-divider {
                  margin: 0;
                  width: 97%;
                }
                table {
                  margin-top: 8px;
                  .total {
                    margin-top: 16px;
                    td {
                      color: $color-navy;
                      font-family: $optimaregular;
                      font-size: 16px;
                      letter-spacing: 0.25px;
                      line-height: 1.25;
                      text-transform: uppercase;
                    }
                  }
                }
                td {
                  background-color: $color-white !important;
                  font-size: 14px;
                  padding-#{$ldirection}: 0;
                  &:last-child {
                    padding-#{$rdirection}: 0 !important;
                  }
                  &.vat-include {
                    color: $color-light-gray;
                    font-size: 8px;
                    text-transform: uppercase;
                  }
                  #checkout_shipmethod {
                    display: block;
                    margin: 8px 0 12px;
                    padding-#{$ldirection}: 0;
                    position: relative;
                    width: 100%;
                    .shipping-msg {
                      border: 1px solid $color-grey-light;
                      padding: 13px 14px;
                    }
                    #delivery-options {
                      border-bottom: 1px solid $color-grey-light;
                      margin: 0 auto 10px;
                      padding: 0 0 12px;
                      width: 100%;
                      .shipform_fields {
                        background: $color-grey-transparent;
                        border: 1px solid $color-navy;
                        margin-bottom: 0;
                        padding: 10px 16px;
                        .ship-method-group {
                          label {
                            display: none;
                          }
                          select {
                            appearance: none;
                            background: 0;
                            border: 0;
                            height: auto;
                            line-height: 1.7;
                            margin: 0;
                            padding: 0;
                          }
                        }
                      }
                    }
                  }
                  .total {
                    padding-top: 9px;
                    td {
                      color: $color-black;
                      font-size: 16px;
                      font-weight: normal;
                      line-height: 1.35;
                    }
                  }
                  &.spacer {
                    display: none;
                  }
                  &.price {
                    color: $color-navy;
                    font-size: 12px;
                    font-weight: bold;
                    padding: 0;
                    width: 50%;
                  }
                }
              }
              .loyalty-points-description__panel {
                padding: 10px 0 0;
              }
            }
            .offer-code-panel {
              background: $color-white;
              border-bottom: 0;
              border-top: 1px solid $color-navy;
              margin-top: 0;
              padding: 14px 13px 13px;
              &__title {
                color: $color-navy;
                font-size: 16px;
                letter-spacing: 0.25px;
                line-height: 1.25;
                margin: 0;
                padding: 0;
                text-transform: uppercase;
              }
              .offer_msg {
                color: $color-medium-green;
                font-size: 12px;
                letter-spacing: 1.25px;
                padding: 10px 0;
              }
              .error_messages {
                margin: 10px 0 0;
                li {
                  color: $color-maroon;
                  font-size: 12px;
                  list-style: none;
                  padding-bottom: 10px;
                }
              }
              .offer_code_form_container {
                float: $ldirection;
                padding: 3px 0 0;
                width: 354px;
                label {
                  line-height: 4.85;
                  &::before {
                    color: $color-light-gray;
                    font-size: 12px;
                  }
                }
                .js-label-mode {
                  & + label {
                    &::before {
                      transform: translateY(-22px) scale(0.8, 0.8);
                    }
                  }
                }
                input[type='text'] {
                  box-shadow: none;
                  border: 1px solid $color-navy;
                  height: 44px;
                }
              }
              .offer_submit {
                background: $color-navy;
                border: 0;
                color: $color-white;
                font-size: 16px;
                font-weight: bold;
                height: 44px;
                margin: 15px 0 4px;
              }
              .one-offer-only {
                display: inline-block;
                padding: 0 0 10px;
                text-align: center;
                width: 100%;
                .offer-code__see-all-offers,
                .overlay-ready {
                  display: block;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: normal;
                  letter-spacing: 1.25px;
                  line-height: 1.34;
                  margin-top: 10px;
                  text-align: $ldirection;
                  text-transform: uppercase;
                }
              }
              &#offer-code-panel {
                #one-offer-only {
                  font-size: 12px;
                  display: block;
                  padding: 0;
                }
              }
            }
            .links-panel {
              border-top: 1px solid $color-navy;
              margin-top: 0;
              padding-bottom: 36px;
              .links_list {
                li {
                  line-height: 1;
                }
              }
              &__title {
                color: $color-navy;
                font-size: 16px;
                font-weight: normal;
                letter-spacing: 0.25px;
                line-height: 1.25;
                margin: 32px auto 30px;
                padding: 0;
              }
              .link,
              .overlay-link {
                color: $color-navy;
                display: block;
                font-size: 12px;
                font-weight: normal;
                letter-spacing: 1.25px;
                line-height: 1.34;
                margin-bottom: 18px;
              }
            }
            .payment-panel {
              margin: 0;
            }
          }
        }
        .viewcart-items {
          background: $color-white;
          border-top: 1px solid $color-navy;
          padding: 14px 13px;
          .shopping-bag-panel {
            color: $color-black;
            &__title {
              border-bottom: 0;
              color: $color-navy;
              font-size: 16px;
              font-weight: normal;
              letter-spacing: 0.25px;
              line-height: 1.25;
              text-transform: uppercase;
              .edit-cart {
                font-size: 10px;
                &::before {
                  @include swap_direction(margin, 4px 4px 0 0);
                  background-image: url('/media/images/checkout/edit_cart_icon.png');
                  background-size: contain;
                  bottom: 4px;
                  content: '';
                  float: $rdirection;
                  height: 18px;
                  position: relative;
                  width: 18px;
                }
              }
            }
          }
          .cart-item-rows {
            background: $color-white;
            .shopping-bag-panel {
              color: $color-black;
              &__title {
                border-bottom: 0;
                font-size: 22px;
                font-weight: normal;
                letter-spacing: 0.15px;
                line-height: 1.35;
                padding: 24px 24px 16px;
              }
            }
            .cart-item {
              display: flex;
              margin: 8px 0;
              padding: 0;
              width: 100%;
              &__thumb {
                @include swap_direction(margin, 9px 23px 9px 16px);
                padding: 0;
                width: 83px !important;
                img {
                  height: 115px;
                  width: 83px;
                }
              }
              &__desc {
                padding: 9px 0;
                width: 67%;
                &-info {
                  width: 100%;
                  .size {
                    font-size: 8px;
                    letter-spacing: 1.5px;
                    line-height: 2;
                    text-transform: uppercase;
                  }
                }
              }
              &__product-name {
                color: $color-navy;
                font-size: 12px;
                font-weight: normal;
                letter-spacing: 0.1px;
                line-height: 1.35;
                margin-bottom: 4px;
                text-transform: uppercase;
              }
              &__product-subname {
                display: none;
              }
              &__swatch {
                height: 16px;
                margin-#{$rdirection}: 5px;
                width: 16px;
              }
              &__color {
                color: $color-navy;
                font-size: 8px;
                font-weight: normal;
                letter-spacing: 1.15px;
                line-height: 2;
                text-transform: uppercase;
              }
              &__qty {
                border: 0;
                font-size: 8px;
                letter-spacing: 1.5px;
                line-height: 2;
                margin-bottom: 6px;
                padding: 4px 0 0;
                position: static;
                width: 100%;
                &-label {
                  font-size: 8px;
                  margin-bottom: 0;
                  min-height: auto;
                }
              }
              &__price {
                display: block;
                font-size: 12px;
                letter-spacing: 0.25px;
                line-height: 1.7;
                del {
                  color: $color-light-gray;
                  padding-#{$rdirection}: 12px;
                }
              }
              &__actual-price {
                margin-#{$rdirection}: 12px;
                text-decoration: line-through;
              }
              &__total {
                letter-spacing: 0.25px;
                position: static;
              }
            }
          }
        }
      }
    }
  }
  &#shipping .continue-shipping-button,
  &#payment .continue-payment-button {
    display: block;
  }
  &#shipping .continue-payment-button,
  &#payment .continue-shipping-button {
    display: none;
  }
  &#shipping {
    .page-wrapper {
      .pg_wrapper {
        .checkout_container {
          .viewcart-items {
            .gift-message {
              display: none;
            }
          }
        }
      }
    }
  }
  &#payment {
    #review-address-info {
      .transaction-details {
        .transaction-item dl dt {
          height: auto;
        }
        .checkout__subtitle {
          color: $color-navy;
          font-family: $optimaregular;
          font-size: 16px;
          font-weight: normal;
          letter-spacing: 0.25px;
          line-height: 1.25;
          margin-bottom: 0;
          position: relative;
          width: 100%;
        }
      }
    }
    .checkout__content {
      .checkout-page-title {
        margin: 45px 0 16px;
      }
    }
    .page-wrapper {
      .pg_wrapper {
        .checkout_container {
          .viewcart-items {
            .gift-message {
              &.gift-message-hide {
                display: none;
              }
              &.gift-message-show {
                display: block;
                .gift-message-item {
                  display: flex;
                  margin: 8px 0;
                  padding: 0;
                  width: 100%;
                  &__thumb {
                    flex: 1 1 30%;
                    margin: 10px 16px;
                    .gift-image {
                      height: auto;
                      width: 100%;
                    }
                  }
                  &__desc {
                    color: $color-navy;
                    flex: 1 1 70%;
                    font-family: $optimaregular;
                    font-weight: normal;
                    letter-spacing: 1.25px;
                    padding: 10px;
                    .checkout__subtitle {
                      font-size: 12px;
                      line-height: 1.4;
                      margin-bottom: 8px;
                    }
                    .gift-message-item__content {
                      font-size: 10px;
                      line-height: 1.8;
                      text-transform: capitalize;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
